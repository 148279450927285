import React from 'react';
import { InputProp } from '../../interfaces/Form';

interface SelectInputProps extends InputProp {
	options: string[];
	onChange: (value: any) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({ id, gridSize, value, onChange, options }) => {
	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = e.target.value as string;
		onChange(selectedValue);
};

  	return (
		<div className={`input-div size-${gridSize} select-input`}>
			<select
				name={`select-input-${id}`}
				id={`select-input-${id}`}
				value={value as any}
				onChange={handleSelectChange}
			>
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
		</div>
  	);
};

export default SelectInput;