import { en } from "../../pages/tmp";
import { fr } from "../../pages/tmp";

// Define a nested interface for translations with language keys
// interface Translations {
//     [key: string]: {
//         [lang: string]: string;
//     };
// }

// function translate(key: string, lang: string): string {
//     // Load translations from JSON files based on the specified language
//     const translations: Translations = lang === 'fr' ? fr : en;
//     return (translations[key] && translations[key][lang]) || key; // Return the translation or the key if translation is not available
// }

function translate(key:string, lang:string) {
    const translations = require(`../../pages/tmp/${lang}.json`);
    return translations[key] || key; // Retourne la traduction ou la clé si la traduction n'est pas disponible
  }

export default translate;