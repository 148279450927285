import blackCross from "../../assets/icons/black-cross-icon.svg"
import { ScenarioItemProps } from "../../interfaces/Scenarios";
import testIcon from "../../assets/icons/test-icon.svg"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import translate from "../translate/Translate" 

const ScenarioComponent = ({ display, scenario }: ScenarioItemProps) => {

    const [deleteBtn, setDeleteBtn] = useState(false);

    const navigate = useNavigate();

    // function checkDisplayedScenario(display: ScenarioItemProps['display']) {
    //     if ((display?.index - display?.scenarioId) < 4 && (display?.index - display?.scenarioId) >= 0) {
    //         return true;
    //     }
    //     return false;
    // }

    return (

        <button
            onClick={() => { navigate(`/scenarios/${scenario?.name}/${scenario?.id}`); console.log(scenario)}}
            className={ 'component scenario-component active'/* + (checkDisplayedScenario(display) ? 'active ' : 'hidden ' + display?.scenarioId)*/ }>
            <div className="scenario-component-thickness"/>
            <div className="scenario-component-flat">
                <div className="icon">
                    <img alt="File icon" src={ testIcon } className="img" />
                </div>
                <div className="option-layer">
                    <div className="left">
                        <h3 className="scenario-title">{scenario.name}</h3>
                        <p className="scenario-desc">{scenario.description}</p>
                    </div>
                    { deleteBtn &&  <div className="right">
                        <button className="cross">
                            <img alt="File icon" src={ blackCross } className="img" />
                        </button>
                    </div> }
                </div>
            </div>
        </button>
    );
}

export default ScenarioComponent;