import { Navbar } from "../../components/navbar";
import React, { useState, useRef, useEffect } from 'react';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { useForm } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { profileInputs } from "../../components/form/objects/profileInputs";
import  UserAPI  from "../../helpers/api/UserAPI";
import { Form } from "../../components/form";
import translate from "../../components/translate/Translate"

import { getSessionCookie, removeSession } from "../../helpers/Cookies";
import { objectFormatter } from "../../helpers/utils/objectFormatter";
// App.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, OAuthProvider} from 'firebase/auth';
import { Session } from "../../interfaces/API";
import { useDispatch } from "react-redux";
import {setSessionCookie} from "../../helpers/Cookies"
import { size } from "lodash";
import { useLanguage } from "../../components/languageProvider/LanguageContext";
import { getMessaging } from 'firebase/messaging';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBokwKtXPhOOt11mTdgNjl8KJMRHM2JDI4",
    authDomain: "simplyfile-e6b07.firebaseapp.com",
    projectId: "simplyfile-e6b07",
    storageBucket: "simplyfile-e6b07.appspot.com",
    messagingSenderId: "944908277263",
    appId: "1:944908277263:web:a432fe7f57a42bf7d1757a"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const messaging = getMessaging(app); 
//datamocked en attendant l'implémentation du back
interface DataMocked {
    name: string;
    email: string;
    phoneNumber: string;
    birthdate: string;
    location: string;
    numeroLocation: string;
    postal: string;
}


interface Option {
    id: string;
    label: string;
}

const mockedOptions: Option[] = [
    { id: 'option1', label: 'Basic' },
    { id: 'option2', label: 'Medium' },
    { id: 'option3', label: 'Premium' },
];

//accordion and properties
const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


// accordion outside / title
const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

//accordion content
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface ProfileProps {
    defaultImage?: string;
}
const Profile: React.FC<ProfileProps> = ({ defaultImage }) => {
    const cookie = getSessionCookie().accessToken
    const idUser = getSessionCookie()._id
    const nameUser = getSessionCookie().name
    const lastnameUser = getSessionCookie().lastname
    const emailUser = getSessionCookie().email
    const phoneUser = getSessionCookie().phone
	const [name, setName] = useState(getSessionCookie().name);
    const [User, setUser] = useState(null)
    const [googleAuthenticated, setGoogleAuthenticated] = useState(false);
    const [microsoftAuthenticated, setMicrosoftAuthenticated] = useState(false);
    // const [googleAuthenticatedToken, setGoogleAuthenticatedToken] = useState("");
    // const [microsoftAuthenticatedToken, setMicrosoftAuthenticatedToken] = useState("");



    const _setupNewSessionGoogle = (userToken:any) => {
        console.log("oauthtoken 1 :",getSessionCookie().googleOauthtoken)
        const session: Session = {
            name: getSessionCookie().name,
            lastname: getSessionCookie().lastname,
            email: getSessionCookie().email,
            accessToken: getSessionCookie().accessToken,
            _id: getSessionCookie()._id,
            phone: getSessionCookie().phone,
            googleOauthtoken: userToken,
            microsoftOauthtoken: getSessionCookie().microsoftOauthtoken,
        }
        setSessionCookie(session)
        console.log("oauthtoken é :",getSessionCookie().googleOauthtoken)
    }

    const _setupNewSessionMicrosoft = (userToken:any) => {
        console.log("oauthtoken 1 :",getSessionCookie().microsoftOauthtoken)
        const session: Session = {
            name: getSessionCookie().name,
            lastname: getSessionCookie().lastname,
            email: getSessionCookie().email,
            accessToken: getSessionCookie().accessToken,
            _id: getSessionCookie()._id,
            phone: getSessionCookie().phone,
            googleOauthtoken: getSessionCookie().googleOauthtoken,
            microsoftOauthtoken: userToken,
        }
        setSessionCookie(session)
        console.log("oauthtoken é :",getSessionCookie().microsoftOauthtoken)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (getSessionCookie().googleOauthtoken)
                    setGoogleAuthenticated(true)
                if (getSessionCookie().microsoftOauthtoken)
                    setMicrosoftAuthenticated(true)
                // console.log('googleAuthenticatedToken VEFORE: ', getSessionCookie().googleOauthtoken)
                // console.log('microsoftAuthenticatedToken ', getSessionCookie().microsoftOauthtoken)
                // setGoogleAuthenticatedToken(getSessionCookie().googleOauthtoken)
                // setMicrosoftAuthenticatedToken(getSessionCookie().microsoftOauthtoken)
                // console.log('googleAuthenticatedToken AFTER : ', googleAuthenticatedToken)
                // if (googleAuthenticatedToken !== "") {
                //     console.log("ICI")
                //     setGoogleAuthenticated(true)
                // }
                // if (microsoftAuthenticatedToken !== "")
                //     setMicrosoftAuthenticated(true)
                setImageUrl(`https://alfred-app.fr/api-user/profile-image/${idUser}`)
                console.log('idUser : ', idUser)
                const response = await UserAPI.get(idUser, cookie)
                console.log('response : ',response)
                // setImageUrl()
                // fetch(`https://alfred-app.fr/api-user/user`, {
                // // fetch('http://localhost:8000/api-file/authenticate/google', {
                // method: 'GET',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': `Bearer ${cookie}`
                // },
                // // body: JSON.stringify({ idToken: userToken, userEmail: userEmail, accessToken: usersToken})
                // })
                // .then(response => response.json())
                // .then(data => {
                //     console.log(data)
                // // const base64AttachmentData = data.attachmentData;
                // //                 // Créer un objet Blob à partir des données encodées en base64
                // //     const blob = b64toBlob(base64AttachmentData);
        
                // //     // Créer une URL blob pour le Blob
                // //     const blobUrl = URL.createObjectURL(blob);
        
                // //     // Créer un lien de téléchargement et déclencher le téléchargement
                // //     const a = document.createElement('a');
                // //     a.href = blobUrl;
                // //     a.download = 'nom_de_la_piece_jointe.png';
                // //     document.body.appendChild(a);
                // //     a.click();
                // //     document.body.removeChild(a);
        
                // //     // Fonction pour convertir une chaîne base64 en objet Blob
                // //     function b64toBlob(base64Data:any) {
                // //         const byteCharacters = atob(base64Data);
                // //         const byteNumbers = new Array(byteCharacters.length);
                // //         for (let i = 0; i < byteCharacters.length; i++) {
                // //             byteNumbers[i] = byteCharacters.charCodeAt(i);
                // //         }
                // //         const byteArray = new Uint8Array(byteNumbers);
                // //         return new Blob([byteArray], { type: 'application/octet-stream' });
                // //     }
                // // console.log('Réponse du serveur:', data);
                // // Traiter la réponse du serveur si nécessaire
                // })
                // .catch(error => {
                // console.error('Erreur lors de l\'envoi du token au backend:', error);
                // });
            } catch (error) {
                console.error('Erreur lors de la récupération des données utilisateur:', error);
            }
        };

        // Appelez la fonction fetchData dès que le composant est monté
        fetchData();
    }, []);

    // Définition des scopes nécessaires
const googleScopes = [
    'https://www.googleapis.com/auth/gmail.readonly', // Exemple de scope pour la lecture des e-mails Gmail
    // Ajoutez d'autres scopes nécessaires ici...
  ];
	const { lang, changeLanguage } = useLanguage();
  
    const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    googleScopes.forEach(scope => {
        provider.addScope(scope);
    });
    signInWithPopup(auth, provider)
    .then((result:any) => {
        // L'utilisateur est authentifié avec succès
        // Récupérer le token d'authentification de l'utilisateur
        console.log(result)
        const usersToken = result._tokenResponse.oauthAccessToken
        const userEmail = result._tokenResponse.email
        result.user.getIdToken()
        .then((userToken:[]) => {
        setGoogleAuthenticated(true)
        _setupNewSessionGoogle(userToken)
        // Créez un client OAuth2
        // const oauth2Client = new OAuth2();

        // // Configurez l'authentification OAuth2 avec votre token d'accès
        // oauth2Client.setCredentials({
        //   access_token: ACCESS_TOKEN
        // });

        // // Créez un client Gmail
        // const gmail = google.gmail({ version: 'v1', auth: oauth2Client });
        // try {
        //   const response = gmail.users.messages.list({ userId: 'me', maxResults: 5 });
        //   const messages = response.data.messages;
        //   // Récupérez les détails de chaque e-mail
        //   const emails = Promise.all(messages.map(async message => {
        //     const email = gmail.users.messages.get({ userId: 'me', id: message.id });
        //     return email.data;
        //   }));
        //   // Traitez les e-mails récupérés ici
        //   console.log('Derniers e-mails :', emails);
        //   return emails;
        // } catch (error) {
        //   console.error('Erreur lors de la récupération des derniers e-mails :', error);
        //   throw error;
        // }
        // Envoyer le token d'authentification au backend
        fetch('https://alfred-app.fr/api-file/authenticate/google', {
        // fetch('http://localhost:8000/api-file/authenticate/google', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idToken: userToken, userEmail: userEmail, accessToken: usersToken})
        })
        .then((response:any) => {
            response.json()
        })
        .then((data:any) => {
            setGoogleAuthenticated(true);   
            const base64AttachmentData = data.attachmentData;
                        // Créer un objet Blob à partir des données encodées en base64
                const blob = b64toBlob(base64AttachmentData);

                // Créer une URL blob pour le Blob
                const blobUrl = URL.createObjectURL(blob);

                // Créer un lien de téléchargement et déclencher le téléchargement
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = 'nom_de_la_piece_jointe.png';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                // Fonction pour convertir une chaîne base64 en objet Blob
                function b64toBlob(base64Data:any) {
                    const byteCharacters = atob(base64Data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    return new Blob([byteArray], { type: 'application/octet-stream' });
                }
            console.log('Réponse du serveur:', data);
            setGoogleAuthenticated(true);

            // Traiter la réponse du serveur si nécessaire
        })
        .catch(error => {
            console.error('Erreur lors de l\'envoi du token au backend:', error);
        });
        })
        .catch((error:any) => {
        // Gérer les erreurs d'authentification
        console.error('Erreur d\'authentification :', error);
        });
    });
    };

    const signOutFromGoogle = () => {
        // Code pour déconnexion de Google
        setGoogleAuthenticated(false);
        // setGoogleAuthenticatedToken("")
    };

    const signOutFromMicrosoft = () => {
        // Code pour déconnexion de Microsoft
        setMicrosoftAuthenticated(false);
        // setMicrosoftAuthenticatedToken("")
    };
  
  // Fonction de connexion avec Microsoft
  const signInWithMicrosoft = () => {
    const provider = new OAuthProvider('microsoft.com');
    // provider.addScope('mail.read');
    provider.addScope('mail.read');
    signInWithPopup(auth, provider)
    .then((result:any) => {
      // L'utilisateur est authentifié avec succès
      // Récupérer le token d'authentification de l'utilisateur
      console.log(result)
      const usersToken = result._tokenResponse.oauthAccessToken
      const userEmail = result._tokenResponse.email
      result.user.getIdToken()
      .then((userToken:any) => {
        setMicrosoftAuthenticated(true)
        _setupNewSessionMicrosoft(userToken)
        console.log('Token d\'authentification de l\'utilisateur :', userToken);
        // Envoyer le token d'authentification au backend
        fetch('http://localhost:8000/api-file/authenticate/microsoft', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ idToken: userToken, userEmail: userEmail, accessToken: usersToken})
        })
        .then(response => response.json())
        .then(data => {
          console.log('Réponse du serveur:', data);
          // Traiter la réponse du serveur si nécessaire
        })  
        .catch(error => {
          console.error('Erreur lors de l\'envoi du token au backend:', error);
        });
      })
      .catch((error:any) => {
      // Gérer les erreurs d'authentification
      console.error('Erreur d\'authentification :', error);
      });
    });
  };

    // datammocked pour le back
    const dataMocked: DataMocked = {
        name: "Xavier Gimeno",
        email: "xavier@example.com",
        phoneNumber: "1234567890",
        birthdate: "2000-01-01",
        location: "New York",
        numeroLocation: '5',
        postal: '13009',

    };

    // open de l'accordion
    const [expanded, setExpanded] = React.useState<string | false>('false');

    // handle change foyer
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    // permet d'edit et mettre les infos direct dans les inputs
    const { register, handleSubmit } = useForm({
        defaultValues: dataMocked,
    });
    const handleSubmitProfileInfo = async (data: {email?: string, phoneNumber?: string, birthdate?: string, adresse?: string}) => {
        console.log(data)
        const response = await UserAPI.modifieUser(undefined, undefined, data.email, undefined, data.phoneNumber, undefined, data.birthdate, data.adresse);
        console.log("respond", response);	
    };
    const parseFormValues = (formValues: Record<string, string | boolean>): Record<string, string | boolean> => {
        const array = ["email", "phoneNumber", "birthdate", "adresse"];
        const formatteObj = objectFormatter(formValues, array);

        console.log(formatteObj)
        return formatteObj;
    };
    // permet le submit des données de foyer
    const onSubmit = (formData: DataMocked) => {
        console.log(formData); // Handle form submission here
    };

    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };
    const [image, setImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(defaultImage || null);

    // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const file = event.target.files ? event.target.files[0] : null;
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImageUrl(reader.result as string);
    //         };
    //         reader.readAsDataURL(file);

    //         setImage(file);
    //     }
    // };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        console.log("Selected file:", file); // Log the selected file
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
    
            setImage(file);
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
        setImageUrl(null);
        fileInputRef.current?.click();
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [isModalOpenbis, setIsModalOpenbis] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
  
    const handleItemClick = (item:string) => {
      setSelectedItem(item);
      setIsModalOpenbis(true);
    };

    const handleValiderClick = async () => {
        if (image){
            const response =  await UserAPI.modifieUser(undefined, undefined, undefined, undefined, undefined, image);
            console.log(response);
       }
    };
    return (

        <div className="page profile-page">
            <div className="page-container">
                <div className="container-thickness" />
				<div className="container-flat">
                <div className="profile-background">
                <Navbar />
                <div className="vert-divider" />

            {isModalOpen && (
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    zIndex: 50,
                    top: '50%',
                    left: '280%',
                    transform: 'translate(-50%, -50%)',
                    background: 'rgbargba(241, 241, 241, 0.7)',  // Slight white tint
                    backdropFilter: 'blur(10px)',   // Blur for the glassmorphism effect
                    border: '1px solid rgba(255, 255, 255, 0.2)',  // Slight border for depth
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), inset 0px 4px 6px rgba(255, 255, 255, 0.1)',  // External & internal shadow for a glassy look
                    padding: '35px',
                    borderRadius: '15px',  // Rounded corners
                    height: '70%',
                    width: '300%'
                }}>
                    <div >
                        {imageUrl ? (
                            <div>
                                <img src={imageUrl} alt="Profile" />
                            </div>
                        ) : (
                            <p>{translate("No profile picture uploaded.",lang)}</p>
                        )}

                        {!image && !imageUrl && (
                            <input ref={fileInputRef} type="file" accept="image/*" onChange={handleImageChange} />
                        )}

                        <button onClick={handleValiderClick}
                            style={{
                                marginTop:"20px",
                                justifySelf: 'center',
                                background: 'rgba(241, 241, 241, 0.7)',
                                color: '#fff',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                            }}
                        >{translate('Validate', lang)}</button>

                        <button onClick={handleRemoveImage}
                            style={{
                                marginLeft:"15px",
                                justifySelf: 'center',
                                background: 'rgba(241, 241, 241, 0.7)',
                                color: '#fff',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',

                            }}
                        >{translate("Delete",lang)}</button>
                    </div>


                    <hr />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridTemplateRows: 'repeat(3, 1fr)',
            gap: '10px',
            width: '400px',
            height: '300px',
            border: '1px solid white',
            padding:'20px',
            marginBottom:'20%',
            marginLeft: '20px'
        }}>
            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>1</div>
            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>2</div>
            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>
                        <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>4</div>
                        <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>3</div>
            <div style={{
                backgroundColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #aaa'
            }}>12</div>
        </div>

                        <button
                            onClick={toggleModal}
                            style={{
                                background: 'rgba(255, 255, 255, 0.2)',
                                color: '#fff',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                marginLeft: '20px'
                            }}
                        >
                            {translate("Close",lang)}
                        </button>
                    </div>

                </div>
            )}


            <div className="profile-background">
                <div className="profile-container">
                <div className="profile-name">{name !== undefined ? name.toUpperCase() : "undefined"}</div>

                {/* <div className="profile-iconBG" onClick={toggleModal} style={{ cursor: 'pointer' }}>
                    <PermIdentityOutlinedIcon className="profile-icon" />
                </div> */}
                <div className="profile-iconBG" onClick={toggleModal} style={{ cursor: 'pointer' }}>
                {imageUrl ? (
                    <img src={imageUrl} alt="Profile" className="profile-icon" />
                ) : (
                    <PermIdentityOutlinedIcon className="profile-icon" />
                )}
            </div>


                <div className="profile-infoContainer">
                    <div className="profile-containerRight">
                    <div className="profile-infoItems">{translate("Name",lang)}</div>
                    <div className="profile-infoItems">{translate("Lastname",lang)}</div>
                    <div className="profile-infoItems">{translate("Email",lang)}</div>
                    <div className="profile-infoItems">{translate("PhoneNumber",lang)}</div>
                    </div>

                    <div className="profile-containerRight">
      <div className="profile-infoItemsbis" onClick={() => handleItemClick(nameUser)}>{nameUser}</div>
      <div className="profile-infoItemsbis" onClick={() => handleItemClick(lastnameUser)}>{lastnameUser}</div>
      <div className="profile-infoItemsbis" onClick={() => handleItemClick(emailUser)}>{emailUser}</div>
      <div className="profile-infoItemsbis" onClick={() => handleItemClick(phoneUser)}>{phoneUser}</div>

      {isModalOpenbis && (
        <div style={{
            display: 'flex',
            flexDirection:'column',
            position: 'absolute',
            zIndex: 70,
            top: '70%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
            background: 'rgbargba(241, 241, 241, 0.7)',  // Slight white tint
            backdropFilter: 'blur(10px)',   // Blur for the glassmorphism effect
            border: '1px solid rgba(255, 255, 255, 0.2)',  // Slight border for depth
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), inset 0px 4px 6px rgba(255, 255, 255, 0.1)',  // External & internal shadow for a glassy look
            padding: '35px',
            borderRadius: '15px',  // Rounded corners
            height: '70%',
            width: '200%'
        }}>
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpenbis(false)} style={{ fontSize: "24px", cursor: "pointer" }}>&times;</span>
            {/* <p>You clicked on: {selectedItem}</p> */}
            <Form
                        inputs={ profileInputs }
                        style={{ justify: "flex-center", align: "flex-center", gridSize: 12 }}
                        button={{
                            active: true,
                            name: "valider le changement",
                            style: {
                                justify: "flex-center",
                                align: "flex-start",
                                gridSize: 12,
                                color: 'dark'
                            }}}
                        navigate={{ active: false }}
                        handleFunction={ handleSubmitProfileInfo }
                        parseFormValues={ parseFormValues }
                    />
            {/* Add modal content here */}
          </div>
        </div>
      )}
    </div>



                    
                    {/* </div> */}
                </div>

                {/* <div style={{width:'90%'}}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Foyer</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="profile-infoContainer">

                                    <div className="profile-infoItems" style={{ color: 'black' }}>
                                        Numéro de la rue :
                                    </div>
                                    <div className="profile-infoItemsbis">
                                        <input
                                            className="ajout-input-container"
                                            type="text"
                                            {...register('numeroLocation')}
                                            data-testid="numeroLocation-input"
                                        />
                                    </div>

                                    <div className="profile-infoItems" style={{ color: 'black' }}>
                                        Adresse :
                                    </div>
                                    <div className="profile-infoItemsbis">
                                        <input
                                            className="ajout-input-container"
                                            type="text"
                                            {...register('location')}
                                            data-testid="location-input"
                                        />
                                    </div>

                                    <div className="profile-infoItems" style={{ color: 'black' }}>
                                        code postale :
                                    </div>
                                    <div className="profile-infoItemsbis">
                                        <input
                                            className="ajout-input-container"
                                            type="text"
                                            {...register('postal')}
                                            data-testid="postal-input"
                                        />
                                    </div>
                                </div>
                                <div className='profiler-btn-container-vali'>
                                    <button className='profile-btn-validated' type="submit">Valider la reconnaissance</button>
                                </div>
                            </form>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>Abonnement</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <RadioGroup>
                                {mockedOptions.map((option) => (
                                    <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.label} />
                                ))}
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>
                </div> */}
                <div>
                </div>
                <div className="authentification" style={{color:'#48CAE4', marginTop:'20px', marginBottom:'20px', display:"flex", justifyContent:'left', width:'100%'}}>{translate("Connection to mailboxes :",lang)}</div>
                <div style={{color:'#CAF0F8', marginTop:'20px', marginBottom:'20px', fontSize:'14px', width:'100%', }} > 
                <div>
                {translate("Connecting to mailboxes allows you to retrieve, sort and store your administrative documents.",lang)}
                </div>
                <div>
                    {translate("Here are the authorized platforms :", lang)}
                </div>
                </div>
                <div style={{display:"flex", justifyContent:"left", gap:'80px'}}>
                    {/* <p>Welcome, {userOauth}!</p> */}
                    {googleAuthenticated ? (
                        <button onClick={signOutFromGoogle} className="login-with-google-btn">
                            {translate("Logout from Google",lang)}
                        </button>
                    ) : (
                        <button onClick={signInWithGoogle} className="login-with-google-btn">
                            {translate("Logout from Google",lang)}
                        </button>
                    )}
                    {microsoftAuthenticated ? (
                        <button onClick={signOutFromMicrosoft} className="login-with-microsoft-btn"> 
                            {translate("Login with Google",lang)}
                        </button>
                    ) : (
                        <button onClick={signInWithMicrosoft} className="login-with-microsoft-btn"> 
                            {translate("Login with Microsoft",lang)}
                        </button>
                    )}
                </div>
        </div>
            </div>

            <div className="profile-backgroundBis">
                <div className="profile-btn-containerBis">
                    <button className='navBarBtnBlue'>
                        <p className='btnFront'>{translate("Preview",lang)}</p>
                    </button>
                    <div className="btnFont" onClick={() => console.log('acti')}>{translate("Activity",lang)}</div>
                    <div className="btnFont" onClick={() => console.log('func')}>{translate("Features",lang)}</div>
                    <div className="btnFont" onClick={() => console.log('outils')}>{translate("Tools",lang)}</div>
                </div>
                <div className="white-rectangle"></div>
                <div className="profile-btn last-activity">{translate("Last Activity",lang)}</div>
            </div>
            <div className="white-rectangle"></div>
        </div>
        </div>
        </div>
        </div>
    );
};

export default Profile;
