import React, { useEffect, useState } from 'react';
import en from './en.json';
import fr from  './fr.json';

const Tmp: React.FC = () => {
  const [jsonData, setJsonData] = useState<{
    welcome: { en: string; fr: string };
    greeting: { en: string; fr: string };
  } | null>();
  const fileURL = new URL('./en.json', import.meta.url);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use the imported file path
        const response = await fetch(fileURL);
        // const response = await fetch(fileTMP);

        
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching JSON:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* Render your JSON data here */}
      {jsonData && (
        <pre>{JSON.stringify(jsonData, null, 2)}</pre>
      )}
    </div>
  );
};

export default Tmp;
