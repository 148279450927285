import React, { ChangeEvent, useState, useEffect } from 'react';
import axios from 'axios';
import { Navbar } from "../../components/navbar";
import { useForm } from 'react-hook-form';
import { DragEvent } from '../../components/dragEvent';
import { useNavigate } from "react-router-dom";
import FilesAPI from '../../helpers/api/FileAPI'
import { useLanguage } from '../../components/languageProvider/LanguageContext';
import translate from '../../components/translate/Translate';

const MAX_FILE_SIZE_MB = 1;

// interface Tag {
//   _id: string;
//   name: string;
//   value: string;
// }

// interface ServerResponse {
//   _id: string;
//   userId: string;
//   fileType: string;
//   name: string;
//   extension: string;
//   size: number;
//   tags: Tag[];
//   __v: number;
//   typeId: number;
//   typeName: string;
// }

const AddDocument = () => {
  const { lang, changeLanguage } = useLanguage();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [serverResponse, setServerResponse] = useState<any>(null);

  const [isUploading, setIsUploading] = useState(false);


  const navigate = useNavigate();

  const handleClick = () => {
    // Redirect to another page when the container is clicked
    const formData = new FormData(document.querySelector('form') as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());
    console.log("Form data:", data);
  

    // navigate('/dashboard');
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log("File input change detected");

    const file = event.target.files && event.target.files[0];
    if (file) {
      if (validateFileSize(file)) {
        console.log("File detected:", file.name);

        setSelectedFile(file);
        setSelectedFileUrl(URL.createObjectURL(file));
        // No need to pass the file to uploadFile here
        // uploadFile();
      } else {
        alert(`File size exceeds the maximum allowed size of ${MAX_FILE_SIZE_MB}MB.`);
      }
    }
  };


  const validateFileSize = (file: File) => {
    const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
    // const fileSizeInKB = file.size / 1024; // Convert to KB
    return fileSizeInMB <= MAX_FILE_SIZE_MB;
  };

  //handle the drag of the file
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  // handle the drop of the file
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    if (file) {
      if (validateFileSize(file)) {
        setSelectedFile(file);
        setSelectedFileUrl(URL.createObjectURL(file));
      } else {
        alert(`File size exceeds the maximum allowed size of ${MAX_FILE_SIZE_MB}MB.`);
      }
    }
  };

  const loaderStyle = {
    position: "absolute" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex" as const,
    justifyContent: "center" as const,
    alignItems: "center" as const,
    color: "#fff",
    fontSize: "2em",
    zIndex: 9999,
    background: "rgba(243, 255, 32, 0.5)",
  };

  const overlayStyle = {
    ...loaderStyle,
    background: "rgba(255, 0, 0, 0.5)",
  };



  const uploadFile = async () => {
    if (!selectedFile) {
      console.log("No file selected to upload");
      return;
    }
    setIsUploading(true);
    try {
      const response = await FilesAPI.uploadFile(selectedFile);
      console.log("response:", response);
      console.log("Response Data:", response.data);
      setServerResponse(response); // Uncomment this line to set serverResponse
      setIsUploading(false); 
      if (response.status === 200) {
        setIsUploading(false); 
      }
    } catch (error) {
      setIsUploaded(false);
      console.error('Error uploading file:', error);
      console.log("POST request failed.");
      setIsUploaded(false);
      
    } finally {
      setIsUploaded(false);
    }
  };


  interface DataMocked {
    type_de_document: string;
    nom_du_client: string;
    name: string;
    clientNumber: number;
    location: string;
    city: string;
    document_date: string;
    prestataire: string;
    factureNumber: number;
    totalAmount: number;
  }

  const dataMocked: DataMocked = {
    type_de_document: "Facture",
    nom_du_client: "Durand",
    name: "martin",
    clientNumber: 123123,
    location: "123 rue imaginaire",
    city: "Ramonville st agne",
    document_date: "21 décembre 2023",
    prestataire: "EDF",
    factureNumber: 123213223,
    totalAmount: 230.30,
  };

  // to let edit and profile info
  const EditableProfileInfo = () => {
    console.log("serv:", serverResponse)
    const { handleSubmit } = useForm({
      defaultValues: serverResponse, // Use server response data as default values
    });

    const onSubmit = (formData: any) => {
      console.log("formdata:", formData); // Handle form submission here
    };


    return (
      // form with input fill up
      <>
        {serverResponse && (
          <div className='before-form'>
          <form onSubmit={handleSubmit(onSubmit)} className={"form-out"}>
            <div className="profile-infoContainer">
              <div className="profile-titleContainer" >
                <div className="profile-infoItems">{translate("name :",lang)}<input className='ajout-input-container' type="text" value={serverResponse.name} /></div>
                <div className="profile-infoItems">{translate("file type :",lang)} {serverResponse.extension}</div>
                <div className="profile-infoItems">{translate("Size :",lang)} {serverResponse.size}</div>
              </div>

              <div >
                <ul>
                {serverResponse.tags.map((tag: any, index: number) => (
                  // {serverResponse.tags.map((tag: any) => (
                    <div className="profile-tagsContainer" key={tag._id}>
                      <div className="profile-infoItems">{tag.name}</div>
                      {/* <input type="text" className='ajout-input-container' value={tag.value} /> */}

                      <input type="text" className='ajout-input-container' defaultValue={tag.value} name={`tags[${index}].value`} />
                      {/* <input className='ajout-input-container' type="text" value={tag.name} /> */}
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className='profiler-btn-container-vali'>
              <button className='profile-btn-validated' type="submit" onClick={handleClick}>Valider la reconnaissance</button>
              <button className='profile-btn-refused' type="submit">Annuler la mise en ligne</button>
            </div>
          </form>
          </div>

        )}

      </>
    );
  };

  // handle the drag and drop etc..
  return (
    <div className='page upload-page'>
      <div className="page-container">
        <div className="container-thickness" />
        <div className="container-flat">
          <div className='page-background' >

            {/* drag and drop */}
            <div
              className='ajout-doc-container'
              // onDragOver={handleDragOver}
              onDragOver={(event) => event.preventDefault()}
              onDrop={handleDrop}
            >
              <div className='ajout-text'>{translate("Add a document",lang)}</div>
              {!isUploaded && !selectedFile &&(
                <div className="container">
                  <div className="card">
                    <h3>{translate("Upload Files",lang)}</h3>
                    <div className="drop_box">
                      <header>
                        <h4>{translate("Select File here",lang)}</h4>
                      </header>
                      <p>{translate("Files Supported:",lang)} PDF, TEXT, DOC , DOCX</p>
                      <input className="btn" type='file' accept='image/*,.pdf' onChange={handleFileChange} />
                      {/* <button className="btn">Choose File</button> */}
                    </div>
                  </div>
                </div>
              )}
              {selectedFile && !serverResponse &&(
                <div className='contenainer-main-file'>
                  {/* <button onClick={uploadFile}>Submit Filqsdqe</button> */}
                  <div className='selected-file'>
                    {selectedFile.type.startsWith('image/') ? (
                      <img className='selected-image' src={selectedFileUrl ?? undefined} alt='' />
                    ) : (
                      <iframe
                        className='selected-pdf'
                        src={selectedFileUrl ?? undefined}
                        title='Selected PDF'
                        style={{ width: '100%', height: '400px' }}
                      />
                    )}
                  </div>
                  <div className='submit-file'>
                    {selectedFile && <button className="buttons primary save"
                      onClick={uploadFile}
                    ><h2>{translate("Send",lang)}</h2></button>}
                    {selectedFile && <button className="buttons primary cancel"
                        onClick={uploadFile}
                      ><h2>{translate("Back",lang)}</h2></button>}
                  </div>
                </div>
              )}
            </div>

            {/* <div>
              {isUploading && 
            (
              <div style={loaderStyle}>
                <p style={{marginRight:'30px'}}>Chargement...</p>
                <div className='loaderAddDocument' ></div>
              </div>
            )}

            </div> */}


          </div>
          {isUploading && 
            (
              <div style={loaderStyle}>
                <p style={{marginRight:'30px'}}>{translate("Loading...",lang)}</p>
                <div className='loaderAddDocument' ></div>
              </div>
            )}
          <div className='ajout-snd-container' style={{marginTop: '200px'}}>
            <EditableProfileInfo />
          </div>

          <Navbar />
          <div
            onDragOver={(event: React.DragEvent<HTMLDivElement>) => event.preventDefault()}
            onDrop={(event: React.DragEvent<HTMLDivElement>) => handleDrop(event)}
          >
            <DragEvent />
          </div>


        </div>
      </div>
    </div>

  );
};

export default AddDocument;