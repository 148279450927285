import React from "react";

const ContactSection: React.FC = () => {

    return (

        <div className="contact-section" id="contact">

            <div className="top-content">
                <p className="title-text">
                    Contact
                </p>
            </div>

            <div className="bottom-content">
                <p>simplyfile_2025@labeip.epitech.eu</p>
                <p>06.78.26.37.29</p>
            </div>

        </div>
    );
};

export default ContactSection;