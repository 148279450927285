import { fetchAPI }from "./FetchAPI";

import { ScenarioData, Files } from "../../interfaces/Scenarios";
import { getSessionCookie } from "../../helpers/Cookies";
import Cookies from 'js-cookie';

// export interface Files {
// 	isNeeded: boolean,
// 	isInDatabase: boolean,
//   	value: string
// }

// export interface ScenarioData {
//     id: string;
//     name: string;
//     description: string;
// 	tags: Files[];
//     // logo: string;
//     // custom: boolean;
//     // active: boolean;
// }
export class Scenario {

    private id: string;
    private name: string;
    private description: string;
    private tags: Files[];
    // private logo: string;
    // private custom: boolean;
    // private active: boolean;

    constructor(scenario: ScenarioData) {
        this.id = scenario.id;
        this.name = scenario.name;
        this.description = scenario.description;
        this.tags = scenario.tags;
        // this.logo = scenario.logo;
        // this.custom = scenario.custom;
        // this.active = scenario.active;
    }

    toJSON(): ScenarioData {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            tags: this.tags,
            // custom: this.custom,
            // logo: this.logo,
            // active: this.active,
        };
    }
}

class ScenariosAPI {

    static async getAll(/*session: { accessToken: string }*/) {
        // Make the API call
        const json = await fetchAPI('api-file/scenario', 'GET', getSessionCookie().accessToken, 'application/json',  Cookies.get('csrfToken')!);
        return json;
    }

    static async getScenarioFiles(type: string, id: string) {
        const json = await fetchAPI(`api-file/scenario/${ type }/${ id }`, 'GET', getSessionCookie().accessToken, 'application/json', Cookies.get('csrfToken')!);
        return json;
    }

    static async delete(id: string, /*session: { accessToken: string }*/) {
        await fetchAPI(`api-file/scenario/${ id }`, 'DELETE', ''/*'session.accessToken'*/, 'application/json',  Cookies.get('csrfToken')!);
    }

    static async downloadScenario(arrayId: number[], scenarioName: string): Promise<boolean> {
        try {
            const accessToken = getSessionCookie().accessToken;
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idFileType: arrayId }),
            };
    
            const response = await fetch('https://alfred-app.fr/api-file/scenario/download', requestOptions);
            
            if (!response.ok) {
                throw new Error('Failed to download ZIP folder');
            }

            const zipName = scenarioName + '.zip';
    
            const contentDispositionHeader = response.headers.get('Content-Disposition');
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const filenameMatch = contentDispositionHeader && contentDispositionHeader.match(filenameRegex);
            const filename = filenameMatch && filenameMatch[1] ? filenameMatch[1] : zipName;
    
            const blob = await response.blob();
    
            const url = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
    
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
    
            window.URL.revokeObjectURL(url);
    
            return true; // Indicates successful download
        } catch (error) {
            console.error('Error downloading ZIP folder:', error);
            throw error; // Rethrow the error for the caller to handle
        }
    }
    

    // static async create(title: string, desc: string, logo: string, custom: string, session: { accessToken: string }) {
    //     const json = await fetchAPI(`api-file//scenarios`, 'POST', session.accessToken, {
    //         title: title,
    //         desc: desc,
    //         logo: logo,
    //         custom: custom
    //     });
    //     return json;
    // }

    // static async update(id: string, desc: string, title: string, logo: string, session: { accessToken: string }) {
    //     const json = await fetchAPI(`api-file//scenarios/${id}`, 'PATCH', session.accessToken, {
    //         title: title,
    //         desc: desc,
    //         logo: logo,
    //     });
    //     return json;
    // }
}

export default ScenariosAPI;