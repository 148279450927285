import { FileInputProp } from "../../interfaces/Form";

const FileInput = ({ id, type, gridSize = 0, value, name, multiple, onChange } : FileInputProp) => {
    return ( 
        <div className={ "input-file-container size-"/* + gridSize*/ }>
            <input
                className='input-file'
                accept='image/*,.pdf'
                type={ type }
                name={ "path" }
                onChange={(e) => onChange ? onChange(e) : null}
                multiple={ multiple }
            /> 
            <label className='file-label'>+</label>
        </div>
    );
}
 
export default FileInput;