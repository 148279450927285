const SettingsPage = () => {
    return (
        <div className="page dashboard-page">
			<div className="page-container">
				<div className="container-thickness" />
				<div className="container-flat">

                </div>
            </div>
        </div>
    );
}
 
export default SettingsPage;