export const resetEmailInputs = [
	{
		id: 1,
		type: "email",
		gridSize: 8,
		value: '',
		labelValue: "Adresse mail",
		labelInline: false,
	},
   
];