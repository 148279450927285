import React, { useState } from "react";
import SFWebLogo from "../../assets/images/logos/SFWebsiteLogo.svg";
import SFWebLogoHover from "../../assets/images/logos/SFWebsiteLogoBlack.svg";
import { useLanguage } from "../languageProvider/LanguageContext";
import translate from "../translate/Translate";

interface NavElement {
    name: string;
    ref: string;
}

const WebsiteNavbar: React.FC = () => {
    const { lang, changeLanguage } = useLanguage();

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const navElements: NavElement[] = [
        {
            name: translate('Accueil',lang),
            ref: 'home',
        },
        {
            name: 'Services',
            ref: 'services',
        },
        {
            name: translate('About',lang),
            ref: 'about'
        },
        {
            name: 'RoadMap',
            ref: 'roadmap'
        },
        {
            name: 'F.A.Q.',
            ref: 'faq'
        },
    ];

    return (
        <div className={ "webnav-container " + (isHovered ? "hover" : "") + " appeared fade-in-top" }>
            <div className="right-container">
                <button className="web-home-btn">
                    <img
                        onClick={ () => window.location.href = `#home` }
                        src={ (isHovered ? SFWebLogoHover : SFWebLogo) }
                        alt="SimplyFile Logo"
                        className="sf-web-icon" />
                </button>
            </div>
            <div className="left-container">
                { navElements.map((element, index) => (
                    <button
                        onClick={ () => window.location.href = `#${element.ref}` }
                        onMouseEnter={ handleMouseEnter }
                        onMouseLeave={ handleMouseLeave }
                        className="webnav-element"
                        key={ index }>
                        <p className="text">{ element.name }</p>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default WebsiteNavbar;