import CrossIcon from '../../assets/icons/black-cross-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/ReduxStore';
import { setMessage } from '../../redux/Toast';
import { useEffect, useState } from 'react';

const ToasterBeta = () => {
    const dispatch = useDispatch();
    const message = useSelector((state: RootState) => state.toast.toastMessage);
    const title = useSelector((state: RootState) => state.toast.toastTitle);
    const [timerActive, setTimerActive] = useState(false);
    const [isInactive, setIsInactive] = useState(false);
    const [isVisible, setIsVisible] = useState(false); // New state for visibility

    useEffect(() => {
        let inactivityTimer: NodeJS.Timeout;

        const resetInactivityTimer = () => {
            setIsInactive(false);
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                setIsInactive(true);
                dispatch(setMessage({ 
                    message: (
                        <span>
                            Do you want to give us some feedbacks?{' '}
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZfYT50mh5xXZYNBuJ9GFEx8zt6E7x5L8U-M84RW6u7p4l_w/viewform?usp=sf_link"
                            target="_blank"
                            rel="noopener noreferrer">
                                Click here
                            </a>
                        </span>
                    ), 
                    title: 'Inactivité détectée' 
                }));
                // dispatch(setMessage({ message: 'Do you want to give us some feedbacks ?' <a>qsd<a/>, title: 'Inactivité détectée' }));
                setIsVisible(true); // Show the toast
            }, 2000); // 2 secondes d'inactivité
        };

        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keydown', resetInactivityTimer);

        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
        };
    }, [dispatch]);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (message) {
            setTimerActive(true);
            timer = setTimeout(() => {
                dispatch(setMessage({ message: '', title: '' }));
                setIsVisible(false); // Hide the toast after message clears
            }, 10000); // Garder le message pendant 10 secondes

            return () => {
                clearTimeout(timer);
            };
        }
    }, [dispatch, message]);

    useEffect(() => {
        setTimerActive(!!message);
    }, [message]);

    const handleMessageClick = () => {
        // Handle the click action here
        alert('Feedback link clicked!');
        // Optionally, reset the message
        dispatch(setMessage({ message: '', title: '' }));
        setIsVisible(false); // Hide the toast after click
    };

    return (
        <div className={ "toast-container slide-right " + (isVisible ? "visible" : "hidden") }>
            <div className="toast-header">
                <p className='title'>
                    { title }
                </p>
                <button
                    onClick={ () => dispatch(setMessage({ message: '', title: '' })) }
                    className="close-btn">
                    <img src={ CrossIcon } alt="Closing icon" className="btn-icon" />
                </button>
            </div>
            <div className="hor-divider" />
            <div className="toast-body">
                {message && (
                    <span onClick={handleMessageClick} className="feedback-link">
                        { message }
                    </span>
                )}
            </div>
            <div className="hor-divider" />
            <div className="toast-footer">
                <button onClick={ () => dispatch(setMessage({ message: '', title: '' })) } className="close-btn">
                    <p>Fermer</p>
                </button>
            </div>
            <div className={ `timer ${timerActive ? 'start' : ''} ` }>
                <div className="timer-bar-container">
                    <div className="timer-full"/>
                    <div className="timer-hidden"/>
                </div>
            </div>
        </div>
    );
};

export default ToasterBeta;
