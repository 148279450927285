import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionState {
    allScenarios: null | any[];
    scenarios: null | any[];
}

const initialState: SessionState = {
    allScenarios: null,
    scenarios: null,
};

const sessionSlice = createSlice({
    name: 'scenario',
    initialState,
    reducers: {
        setAllScenarios: (state, action: PayloadAction<any[]>) => {
            state.allScenarios = action.payload;
        },
        setScenarios: (state, action: PayloadAction<any[]>) => {
            state.scenarios = action.payload;
        },
    },
});

export const { setAllScenarios, setScenarios } = sessionSlice.actions;

export default sessionSlice.reducer;
