import { useState,useEffect } from "react";
import { AuthComponentProps } from "../../pages/auth/Authentification";
import { Form } from "../form";
import { signUpInputs } from "../form/objects/signUpInputs";
import { objectFormatter } from "../../helpers/utils/objectFormatter";
import UserAPI from "../../helpers/api/UserAPI";
import { useDispatch } from "react-redux";
import { setMessage } from "../../redux/Toast";
import { Session } from "../../interfaces/API";
import translate from "../translate/Translate"
import { useNavigate } from "react-router-dom";
import { sessionSlice, setSession } from '../../redux/Session'
import { ISession } from "../../interfaces/Session";
import { useLanguage } from "../languageProvider/LanguageContext";
import { requestUserPermission, onMessageListener } from '../../helpers/notification';



const SignUp = ({ isShown, setIsSignUp, setShowModal} : AuthComponentProps) => {
	const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const { lang, changeLanguage } = useLanguage();

	function toggleModal() {
        console.log(setShowModal)
		if (setShowModal) {
			setShowModal(!isShown);
		}
	}


    const handleSubmit = async (data: {name: string, email: string, password: string, lastname: string, profilePhoto?: string, phonenumber?: string }) => {
        console.log("Contenu envoyé:", data);
        try {
            const response = await UserAPI.register(data.name, data.lastname, data.email, data.password, data.phonenumber, data.profilePhoto);
            if (response.status === 200) {
                // navigate("/dashboard");
                // setIsSignUp(!isShown);
                // handleButtonClick();
                console.log(response)
                const session: Session = {
                    name: response.name,
                    lastname: response.lastname,
                    email: response.email,
                    accessToken: response.token,
                    _id: response._id,
                    phone: response.phone,
                    googleOauthtoken: "",
					microsoftOauthtoken: "",
                }
                dispatch(setSession(session))
                requestUserPermission().then((token) => {
					if (token) {
					  console.log('Token obtained:', token);
					  UserAPI.pushToken(token)
					}
				});
                toggleModal();
            }
        } catch (error) {
            console.error('Error during registration:', error);
            if (typeof error === 'object' && error) {
                const errorMessage = (error as Error).message;
                dispatch(setMessage({ message: errorMessage, title: 'Error during registration:'}));
            } else {
                dispatch(setMessage({ message: 'An unknown error occurred', title: 'Error during registration:'}));
            }
        }
    };    

    // 1 - FirstName
    // 2 - LastName
    // 3 - EmailAddress
    // 4 - Password
    // 5 - PasswordVerif
    const parseFormValues = (formValues: Record<string, string | boolean>): Record<string, string | boolean> => {
        const array = ["name", "lastname", "email", "password", "passwordVerif", "PhoneNumber"];
        const formatteObj = objectFormatter(formValues, array);
        if (typeof formatteObj.password === 'string' && typeof formatteObj.passwordVerif === 'string') {
            if (formatteObj.password.length < 7 || formatteObj.passwordVerif.length < 7) {
                alert("Les mots de passe doivent contenir au moins 7 caractères !");
                return {};
            }
    
            if (formatteObj.password !== formatteObj.passwordVerif) {
                alert("Les mots de passe ne correspondent pas !");
                return {};
            }
        } else {
            alert("Les mots de passe doivent être des chaînes de caractères !");
            return {};
        }
        delete formatteObj.passwordVerif;
        console.log(formatteObj)
        return formatteObj;
    };

    return (
        <div className={ "component auth-component signup-component " + (isShown ? '' : 'shown') }>
            <div className="background-item">
                {/* <div className="background-element-1"/>
                <div className="background-element-2"/> */}
                <div className="title">
                    <h3 className="">
                        {translate("Register", lang)}
                    </h3>
                </div>
                <div className="content">
                    <Form
                        inputs={ signUpInputs }
                        style={{ justify: "flex-center", align: "", gridSize: 12 }}
                        button={{
                            active: true,
                            name: translate("S'INSCRIRE", lang),
                            style: {
                                justify: "flex-center",
                                align: "flex-center",
                                gridSize: 12,
                                color: 'dark'
                            }}}
                        navigate={{ active: false }}
                        handleFunction={ handleSubmit }
                        parseFormValues={ parseFormValues }
                    />
                    <div className="bottom">
                        <div className="link-to">
                            {/* <button className="auth-link" onClick={ () => toggleModal() }> */}
                            <button className="auth-link" onClick={ () => setIsSignUp(!isShown) }>

                                <p>{translate("Already registered ? connection", lang)}</p>
                                <div className="underline-effect">
                                    <div className="underline-div-hidden" />
                                    <div className="underline-div" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-item-thickness"></div>
        </div>

    )
}

export default SignUp;