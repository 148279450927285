import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../pages/profile/ProfilePage';

export const requestUserPermission = async (): Promise<string | undefined> => {
  try {
    // Demande de la permission d'afficher les notifications
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Si la permission est accordée, obtenir le token
      const currentToken = await getToken(messaging, { vapidKey: 'BOioL9KAisr0FaljHAkutRWur7_G3dBr0j-p1vvf90YTZA8tiwx0_ZM3r09pBfyOjRNTED1JnzLNgmiDG-AxJek' });
      if (currentToken) {
        console.log('User Token:', currentToken);
        return currentToken;
      } else {
        console.log('No registration token available.');
      }
    } else if (permission === 'denied') {
      console.log('Notification permission was denied.');
    } else if (permission === 'default') {
      console.log('Notification permission request was dismissed.');
    }
  } catch (err) {
    console.error('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
