import { Navbar } from "../../components/navbar";
import { Form } from "../../components/form";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserAPI from "../../helpers/api/UserAPI";
import { useLanguage } from "../../components/languageProvider/LanguageContext";
import translate from "../../components/translate/Translate";

const ConfirmationAccount = () => {
    const { lang, changeLanguage } = useLanguage();

    const [title, setTitle] = useState("Loading...");
    const { name, id } = useParams();
    const [confirmed , isConfirmed] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (name && id) {
                const encodedString = name;
                const decodedString = decodeURIComponent(encodedString);
                // Simulating API call with setTimeout
                setTimeout(() => {
                    setTitle(decodedString);
                }, 1000);
            }
        };

        fetchData();
    }, [name, id]);

    const handleResendConfirmation = () => {
        // Mocking resend confirmation logic with an alert
        alert("renvoie");
    };
    const handleConfirm = () => {
        isConfirmed(true);
    };

    return (
        <div className="page scenario-view">
            <div className="page-container">
                <div className="container-thickness" />
                <div className="container-flat">
                    <div style={{ display: 'flex', width: "100%", flexDirection: "column", alignItems: "center", paddingBottom: "0%" }}>
                        <div style={{ width: "90%", height: "80px", backgroundColor: confirmed ? "green" : "#F86757", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div>{translate("Unconfirmed account", lang)}</div>
                        </div>
                        <h2 style={{ display: "flex", marginTop:"50px" }}>Confirmation requise pour {name}</h2>
                        <div style={{ 
                            width: "40%", 
                            backgroundColor: "rgba(255, 255, 255, 0.2)", /* Adjust opacity as needed */
                            backdropFilter: "blur(10px)", /* Adjust blur strength as needed */
                            border: "1px solid rgba(255, 255, 255, 0.1)", /* Adjust border color as needed */
                            borderRadius: "10px", 
                            padding: "20px", 
                            marginTop: "10px" 
                        }}>
                            <p>{translate("You must have received a confirmation link by email when creating your account",lang)}</p>
                            <p>{translate("Please click on this link in order to fully benefit from all the Features",lang)}</p>
                            <p>{translate("If you have not received this email please check your SPAM folder",lang)}</p>
                            {/* <p><a href="#" style={{ color: "blue" }} onClick={handleResendConfirmation}>Je n'ai pas reçu le mail ?</a></p> */}
                            <p style={{ marginBottom:"70px"}}>{translate("thanks",lang)}</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {!confirmed && <button style={{ padding: "10px 20px", background: "#4CAF50", border: "none", borderRadius: "5px", cursor: "pointer", fontSize: "16px" }} onClick={handleConfirm}>Confirmer</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationAccount;
