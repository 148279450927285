import React from "react";
import BgElemService1 from "../../assets/images/svg/BgElemService1.svg";
import BgElemService2 from "../../assets/images/svg/BgElemService2.svg";
import GroupFileElem1 from "../../assets/images/svg/GroupFileElem1.svg";
import GroupFileElem2 from "../../assets/images/svg/GroupFileElem2.svg";
import GroupFileElem3 from "../../assets/images/svg/GroupFileElem3.svg";
import translate from "../translate/Translate";
import { useLanguage } from "../languageProvider/LanguageContext";

const ServicesSection: React.FC = () => {
    const { lang, changeLanguage } = useLanguage();

    return (

        <div className="services-section" id="services">

            <img src={ BgElemService1 } alt="Background decoration services element 1" className="bg-element-serv bg-element-serv-1" />

            <div className="left-content">

                <div className="card-container service-card js-scroll slide-left">
                    <div className="card">
                        <div className="front-face">
                            <p className="">
                                {translate("Auto Add",lang)}
                            </p>
                        </div>
                        <div className="back-face">
                            <p className="">
                            {translate("Once your mailboxes are linked, the different documents that you received by email will be added directly to SimplyFile.",lang)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="card-container service-card js-scroll slide-left">
                    <div className="card">
                        <div className="front-face">
                            <p className="">
                                {translate('Autonomous sorting',lang)}
                            </p>
                        </div>
                        <div className="back-face">
                            <p className="">
                                {translate("Documents are sorted autonomously according to predefined categories,it doesn't matter if they are added automatically or by yourself.",lang)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="card-container service-card js-scroll slide-left">
                    <div className="card">
                        <div className="front-face">
                            <p className="">
                                {translate("One-click steps",lang)}
                            </p>
                        </div>
                        <div className="back-face">
                            <p className="">
                                {translate("Carry out the most common administrative procedures in one click! The application detects the documents you already have and downloads you a ready-to-use file.",lang)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="card-container service-card js-scroll slide-left">
                    <div className="card">
                        <div className="front-face">
                            <p className="">
                                {translate("Relevant Renaming",lang)}
                            </p>
                        </div>
                        <div className="back-face">
                            <p className="">
                                {translate("Documents such as invoices with complex names are renamed explicitly so that it is easier for you to find them.", lang)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="card-container service-card js-scroll slide-left">
                    <div className="card">
                        <div className="front-face">
                            <p className="">
                                {translate("Simple and Intuitive", lang)}
                            </p>
                        </div>
                        <div className="back-face">
                            <p className="">
                                {translate("We tried to design the application in a simple and intuitive way. so that anyone can easily use it on a daily basis.",lang)}
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="right-content">
                <img src={ GroupFileElem1 } alt="Group file decoration element 1" className="file-grp-1 js-scroll fade-in-bottom" />
                <img src={ GroupFileElem2 } alt="Group file decoration element 2" className="file-grp-2 js-scroll fade-in-bottom" />
                <img src={ GroupFileElem3 } alt="Group file decoration element 2" className="file-grp-3 js-scroll fade-in-bottom" />
            </div>
            
            <img src={ BgElemService2 } alt="Background decoration service element 2" className="bg-element-serv bg-element-serv-2" />

        </div>
    );
};

export default ServicesSection;