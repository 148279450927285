import { useState } from "react";
import { DashboardComponent } from "../../interfaces/Dashboard";
import translate from "../translate/Translate"
import { sessionSlice } from "../../redux/Session"
import { getSessionCookie } from "../../helpers/Cookies";
import { useLanguage } from "../languageProvider/LanguageContext";
const WelcomeText =({ display } : DashboardComponent) => {
    const { lang, changeLanguage } = useLanguage();

	const [name, setName] = useState(getSessionCookie().name);
    // console.log("name:",name)
    return (
        <div className={ "welcome-text " + (display ? "shown" : "hidden") + "-div" }>
            <p>
                {translate("Hello",lang)+", "+ name}
            </p>
            <p>
                {translate("Happy to see you again !", lang)} 👋
            </p>
        </div>
    )
}

export default WelcomeText;