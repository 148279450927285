import React from "react";
import BgElmtWeb from "../../assets/images/svg/BackgroundElementWeb.svg";
import ManPict from "../../assets/images/svg/ManHoldingPhone.svg";
import FileElem1 from "../../assets/images/svg/FileElement1.svg";
import FileElem2 from "../../assets/images/svg/FileElement2.svg";
import StatElem1 from "../../assets/images/svg/StatElement1.svg";
import { useLanguage } from "../languageProvider/LanguageContext";
import translate from "../translate/Translate";

const HomeSection: React.FC = () => {
    const { lang, changeLanguage } = useLanguage();

    return (

        <div className="home-section">
            <img src={ BgElmtWeb } alt="Background decoration element" className="bg-element js-appear fade-in" />

                <div className="content-container">

                    <div className="left-content">
                        <p className="main-text js-appear slide-left">
                            {translate("No longer worry about your administrative procedures.",lang)}
                        </p>
                        <p className="desc-text js-appear slide-left">
                            {translate("Entrust SimplyFile with the power to automatically take care of your procedures in a simplified and efficient way!",lang)}
                        </p>
                        <div className="button-div">
                            <button className="btn-discover js-appear slide-left">
                                <p>
                                {translate("Find out how",lang)}
                                </p>
                            </button>
                        </div>
                    </div>

                    <div className="right-content">
                        <img src={ FileElem1 } alt="File decoration element 1" className="elem-1 js-appear fade-in-bottom" />
                        <img src={ FileElem2 } alt="File decoration element 2" className="elem-2 js-appear fade-in-bottom" />
                        <img src={ StatElem1 } alt="Statistics decoration element 1" className="elem-3 js-appear slide-right" />
                        <div className="rect-bg js-appear fade-in" />
                        <img src={ ManPict } alt="Man decoration holding phone" className="pict-man js-appear fade-in" />
                    </div>

                </div>
        </div>
    );
};

export default HomeSection;