import { DashboardComponent } from "../../interfaces/Dashboard";
import translate from "../translate/Translate"
import { useLanguage } from "../languageProvider/LanguageContext";


const InfoCarousel =({ display } : DashboardComponent) => {
    const { lang, changeLanguage } = useLanguage();
    return (
        <div className={ "carousel-div " + (display ? "shown" : "hidden") + "-div" }>
            <div className="carousel-thickness"/>
            <div className="carousel-flat">
                <button className="left-arrow">
                    <div className="arrow"></div>
                </button>
                <div className="content">
                    <div className="title">
                        <p>{translate("New offers!",lang)}</p>
                    </div>
                    <div className="info">
                        <p>
                            {translate("Discover the new offers available especially for you allowing you to save money on your bills",lang)}
                        </p>
                    </div>
                    <div className="buttons">
                        <button className="info-btn" >
                            <p>{translate("Discover",lang)}</p>
                        </button>
                        <div className="nav-menu">
                            <button className="nav-btn"/>
                            <button className="nav-btn select"/>
                            <button className="nav-btn"/>
                        </div>
                    </div>
                </div>
                <button className="right-arrow">
                    <div className="arrow"></div>
                </button>
            </div>
        </div>
    )
}

export default InfoCarousel;