export const fetchAPI = async (url: string, method: string, token: string, contentType : string, csrfToken: string, body?: any) => {
  console.log(csrfToken)
	const options: RequestInit = {
		method,
		headers: {
        	'Authorization': "Bearer " + token,
          'X-CSRF-Token': csrfToken,
          'x-mobile-app': 'true'
        },
        credentials: 'include',
        body: null
    };
    console.log(options.headers);
  
    if (body) {
        if (contentType === 'multipart/form-data') {
          options.body = body;
        } else {
          options.headers = { ...options.headers, 'Content-Type': contentType };
          options.body = JSON.stringify(body);
        }
      }
    // http://alfred-app.fr:8080/
    const res = await fetch(`https://alfred-app.fr/${url}`, options);
    // const res = await fetch(` http://localhost:8080/${url}`, options);
    // const res = await fetch(`${process.env.REACT_APP_API}/${url}`, options);
  
    console.log(`[API] [${new Date().toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}] [${method}] /${url} (${res.status})`);
    const json = await res.json();
  
    if (json.error) {
        throw new Error(json.message);
    }
  
    return json;
};