import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from '../inputs/Input';
import { FormProps } from '../../interfaces/Form';

const Form: React.FC<FormProps> = ({ 
    inputs, 
    style, 
    button, 
    navigate, 
    handleFunction, 
    handleFileFunction, 
    parseFormValues, 
    data
}) => {

    const navigation = useNavigate();

    const [formData, setFormData] = useState({});
    const [files, setFiles] = useState<File[]>([]);

    const handleInputChange = (name: string, value: string | boolean | React.ChangeEvent<HTMLInputElement>) => {
        if (value instanceof File) return;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleInputFile = (input: React.ChangeEvent<HTMLInputElement> | FileList) => {
        const fileList = input instanceof FileList ? input : input.target.files;
        if (fileList) {
            setFiles(Array.from(fileList));
        }
    };

    const handleFileSubmit = async () => {
        if (files.length > 0 && handleFileFunction) {
            await Promise.all(files.map(file => handleFileFunction(file)));
        }
    }

    const handleFormSubmit = async () => {
        const parsedData = parseFormValues ? parseFormValues(formData) : data || formData;

        if (Object.keys(parsedData).length > 0 && handleFunction) {
            await handleFunction(parsedData);
        }

        if (navigate?.active && navigate?.value) {
            navigation('/' + navigate.value);
        }
    };

    const handleSubmit = async () => {
        if (handleFunction) {
            await handleFormSubmit();
        } else if (handleFileFunction) {
            await handleFileSubmit();
        }
    }

    return (
        <div className={`component form-component form${style?.gridSize ? ` grid-${style.gridSize}` : ''}`}>
            <Input
                inputs={inputs}
                justifyContent={style?.justify}
                alignItems={style?.align}
                onInputChange={handleInputChange}
                onFileChange={handleInputFile}
            />
            <div className={`form-button-div align-${button?.style?.align || ''} justify-${button?.style?.justify || ''}`}>
                {button?.active && (
                    <button onClick={handleSubmit} className={`button md ${button?.style?.color || 'color'}`}>
                        <div className="button-thickness" />
                        <div className="button-flat">
                            <p>{button.name}</p>
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Form;