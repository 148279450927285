import { Outlet, Navigate } from "react-router-dom";

type TProtectedRoute = {
	requireAuth: boolean,
	requireAdmin?: boolean,
	redirectPath?: String
};

const ProtectedRoute = ({ requireAuth, requireAdmin = false, redirectPath = '/disclaimer' }: TProtectedRoute) => {

    if (requireAuth)
		return <Navigate to={redirectPath.toString()} replace />;

    return (
		<>
			{ requireAdmin && <Navigate to={redirectPath.toString()} replace /> } 
			{/* { requireAuth &&
				<>
					{ requireEmailCf && <Sidebar /> }
				</>
			} */}
			<Outlet/>
		</>
	)
};

export default ProtectedRoute;