import { useState } from 'react';
import { SwitchInputProp } from "../../interfaces/Form";

const SwitchInput = ({ id, type, gridSize, value, onChange }: SwitchInputProp) => {
    const [isChecked, setIsChecked] = useState(value === 'true' || value === true);

    const handleSwitchChange = () => {
        if (onChange) {
            const newValue = !isChecked;
            setIsChecked(newValue);
            onChange(id, newValue);
        }
    };

    return (
        <div
            id={type + "-type-" + id}
            className={"component input-div size-" + gridSize + " switch-input"}
        >
            <label className="switch">
                <input
                    className="checkbox"
                    type="checkbox"
                    name={type + "-type-" + id}
                    checked={ !isChecked }
                    onChange={handleSwitchChange}
                />
                <span className="slider round"></span>
            </label>
        </div>
    );
};

export default SwitchInput;