import { Navbar } from "../../components/navbar";
import { ScenarioComponent } from "../../components/scenarios";
import { useEffect, useState } from "react";
import ScenarioNavigation from "../../components/scenarios/ScenarioNavigation";
import ScenariosAPI from "../../helpers/api/ScenarioAPI";
import { ScenarioProps, ScenarioResponse } from "../../interfaces/Scenarios";
import { useDispatch } from "react-redux";
import { setMessage } from "../../redux/Toast";
import translate from '../../components/translate/Translate';
import { useLanguage } from "../../components/languageProvider/LanguageContext";

const Scenarios = () => {
    const { lang, changeLanguage } = useLanguage();
    const [currentPage, setCurrentPage] = useState(0);
    const [allScenarios, setAllScenarios] = useState<ScenarioResponse | undefined>(undefined);
    const scenariosPerPage = 4;

    const dispatch = useDispatch();

    useEffect(() => {
        getAllScenarios();
    }, []);

    const getAllScenarios = async () => {
        try {
            const response = await ScenariosAPI.getAll();
            setAllScenarios(response);
        } catch (error) {
            console.error('Error getting scenarios:', error);
            const errorMessage = (error instanceof Error) ? error.message : 'An unknown error occurred';
            dispatch(setMessage({ message: errorMessage, title: 'Error getting scenarios:'}));
        }
    }; 

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="page scenarios-page">
            <div className="page-container">
                <div className="container-thickness" />
                <div className="container-flat">
                    <Navbar />
                    <div className="vert-divider"/>
                    <div className="content-left">
                        <div className="title-text">
                            <p>{translate(`Scenarios`,lang)}</p>
                        </div>
                        <div className="scenarios-container">
                            {allScenarios &&
                                allScenarios.adminScenarios
                                .slice(currentPage * scenariosPerPage, (currentPage + 1) * scenariosPerPage)
                                .map((scenario: ScenarioProps, index: number) => (
                                    <ScenarioComponent
                                        key={index}
                                        scenario={{
                                            id: scenario.id,
                                            name: scenario.name,
                                            description: scenario.description,
                                            tags: scenario.tags
                                        }}
                                    />
                                ))}
                            {!allScenarios &&
                                <div className="loader">
                                    <p>{translate("Loading...",lang)}</p>

                                </div>
                            }
                        </div>
                    </div>
                    <div className="content-right">
                        {allScenarios && allScenarios.adminScenarios &&
                            <ScenarioNavigation
                                currentPage={currentPage}
                                totalScenarios={allScenarios.adminScenarios.length}
                                scenariosPerPage={scenariosPerPage}
                                onPageChange={handlePageChange}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Scenarios;