import React, { useState } from "react";
import { useLanguage } from "../languageProvider/LanguageContext";
import translate from "../translate/Translate";

interface FAQItem {
    question: string;
    answer: string;
}

const FAQSection: React.FC = () => {
    const { lang, changeLanguage } = useLanguage();

    const FAQData: FAQItem[] = [
        {
            question: translate("Q1: How to use your service?", lang),
            answer:
                translate("Simply create an account on http://alfred-app.fr/, once connected you can use the service as you wish!",lang)
            },
        {
            question: translate("Q2: Are my documents secure?", lang),
            answer:
                translate("For the moment the application is in the beta phase, we therefore advise you not to download sensitive documents at the moment. This version aims to be tested as much as possible in order to let us know of any problems you encounter! Obviously, ultimately, the application is intended to be a safe and your documents will obviously be completely secure!",lang)
        },
        {
            question: translate("Q3: What do you do with the data you recover?",lang),
            answer:
                translate("We do not collect any data! All 'data' that may be used are processed algorithmically for the proper functioning of the application. No human ever has access to any information. These are under no circumstances ever sold or used by any third party.",lang)
        },
        {
            question: translate("Q4: Can my family also use my account?",lang),
            answer:
                translate("Obviously ! We plan to implement a Homes feature very soon. From different accounts you can access the same 'My Home' space in order to share common files and folders in the same household while keeping a private space which is your account.",lang)
        },
        {
            question: translate("Q5: How do you retrieve my documents?",lang),
            answer:
                translate("When you create your account, you will go through a configuration that will only have to be done once. This setting allows you to link your different mailboxes so that SimplyFile can automatically add your files directly to your space. You can configure which file types to add or not. Obviously this is not obligatory, you can absolutely refuse these parameters. In this case you can always add your documents by hand using the SimplyFile mobile application. These settings can be reactivated later from your space.",lang)
        },
        {
            question: translate("Q6: Is this a paid service?",lang),
            answer:
                translate("For the moment SimplyFile is completely free throughout its development phase. Enjoy! This will change in the future :)",lang)
        }
    ];

    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const toggleFAQItem = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    return (

        <div className="faq-section" id="faq">

            <div className="top-content">
                <p className="title-text js-scroll slide-left">
                    {translate("Frequently Asked Questions", lang)}
                </p>
            </div>

            <div className="bottom-content">
                { FAQData.map((item, index) => (
                    <button
                        onClick={() => toggleFAQItem(index)}
                        className={ "faq-element js-scroll " + ((index % 2 === 0) ? "slide-right" : " slide-left") }
                        key={index}>
                        <p >{item.question}</p>
                        <div className={"expend-text" + (expandedIndex === index ? " open" : "")}>
                            <p>{ item.answer }</p>
                        </div>
                    </button>
                ))}

            </div>

        </div>
    );
};

export default FAQSection;
