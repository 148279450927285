import { RadioInputProp } from "../../interfaces/Form";
import translate from "../translate/Translate";

type RadioInputProps = RadioInputProp & {
    onChange: (value: string) => void;
};

const RadioInput = ({ id, type, name, options, selectedValue, gridSize, onChange }: RadioInputProps) => {
    
    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = e.target.value;
        onChange(selectedValue);
    };

    return (
        <div
            id={ type + "-type-" + id }
            className={ "input-div size-" + gridSize + " radio-input" }>
            { options?.map((option, index) => (
                <label key={ index }>
                    <input
                        className="radio-label"
                        type="radio"
                        name={ name}
                        value={ option }
                        onChange={ handleOptionChange }
                    />
                    { option }
                </label>
            ))}
        </div>
    );
}

export default RadioInput;