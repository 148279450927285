import { fetchAPI } from "./FetchAPI"; 
import { Name, Email, IUser } from "../../interfaces/IUser";
import { RegisterData, ChangeData } from "../../interfaces/API";
import { ISession } from '../../interfaces/Session'
import { getSessionCookie } from "../../helpers/Cookies";
//{ "token": "your_jwt_token", "text": "Success" }
import Cookies from 'js-cookie';
export class Session {

    public token : string;
    public text : string;

    constructor(session: ISession ) {
       this.token = session.token
       this.text = session.text
    }

    toJSON ()  {
        return {
            token: this.token,
            text: this.text
        }
    }
}

export class User {

    public _id: string;
    public name: Name;
    public email: string;
    // public emails: Email[];
    public username: string;
    public adress: string | undefined;
    public phone: string | undefined;
    public birthDate: string | undefined;

    constructor(user: IUser) {
        this._id = user._id;
        this.username  = user.username;
        this.name = {
            firstName: user.name.firstName,
            lastName: user.name.lastName
        };
        this.email = user.email;
        this.adress = user.adress !== undefined ? user.adress : undefined; // Check if user.address exists
        this.phone = user.phone !== undefined ? user.phone : undefined; // Check if user.phone exists
        this.birthDate = user.birthDate !== undefined ? user.birthDate : undefined; // Check if user.birthDate exists
    }

    toJSON(): { _id: string, name: Name, email: string } {
        return {
            _id: this._id,
            name: this.name,
            email: this.email,
        };
    }
}

class UserAPI {
    static async getCsrf() {
        try {
            const json = await fetchAPI('api-user/csrf-token', 'GET', '','application/json', '');
            console.log("response:", json);
            return json;
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    }

    static async pushToken(token: string) {
        try {
            const json = await fetchAPI('api-user/notificationToken', 'POST', getSessionCookie().accessToken, 'application/json', Cookies.get('csrfToken')!, {
                token: token,
            });
            console.log("response:", json);
            return json;
        } catch (error) {
            console.error('Error during login:', error);
            throw error; // Rethrow the error to propagate it to the calling function
        }
    }

    static async login(email: string, password:string) {
        try {
            const json = await fetchAPI('api-user/login', 'POST', '','application/json',Cookies.get('csrfToken')!, {
                email: email,
                password: password
            });
            console.log("response:", json);
            return json;
        } catch (error) {
            console.error('Error during login:', error);
            throw error; // Rethrow the error to propagate it to the calling function
        }
    }    

    // static async register(name: string, lastname: string, email: string, password: string) {
    //     const res = await fetchAPI('api-user/api/register', 'POST', '', {
    //         name: name,
    //         lastname: lastname,
    //         email: email,
    //         password: password
    //     });
    //     return res;
    // }

    static async register(
        name: string,
        lastname: string,
        email: string,
        password: string,
        phonenumber?: string,
        profilePhoto?: string
    ) {
        const registerData: RegisterData = {
            name: name,
            lastname: lastname,
            email: email,
            password: password
        };
    
        if (phonenumber) {
            registerData.phonenumber = phonenumber; // Ajouter phonenumber si fourni
        }
        if (profilePhoto) {
            registerData.profilePhoto = profilePhoto; // Ajouter profilePhoto si fourni
        }
    
        const res = await fetchAPI('api-user/register', 'POST', '', 'application/json',  Cookies.get('csrfToken')!, registerData);
        return res;
    }   

    // static async changePassword(newPassword:string){
    //     const res = await fetchAPI(`api-user/user`, 'PUT',getSessionCookie().accessToken, {
    //         newPassword : newPassword
    //     });
    //     return res;
    // }

    static async modifieUser(name?: string,
            lastname?: string,
            email?: string,
            password?: string,
            phonenumber?: string,
            profilePhoto? : File, 
            birthdate? : string,
            adress? : string) {
        const token = getSessionCookie().accessToken;

        const formData = new FormData();
        if (name) {
            formData.append('name', name);
        }

        if (lastname) {
            formData.append('lastname', lastname);
        }
    
        if (email) {
            formData.append('email', email);
        }

        if (password) {
            formData.append('password', password);
        }
    
        if (phonenumber) {
            formData.append('phone', phonenumber);
        }
    
        if (profilePhoto) {
            formData.append('profilePhoto', profilePhoto, profilePhoto.name);
        }

        if (birthdate) {
            formData.append('birthdate', birthdate);
        }

        if (adress) {
            formData.append('adress', adress);
        }
    
        const res = await fetchAPI(`api-user/user`, 'PUT', token, 'multipart/form-data',  Cookies.get('csrfToken')!, formData );
        // console.log('userapi:', res);
        return res;
    }

    static async confirmMail(pin : string){
        const res = await fetchAPI(`api-user/verify/${pin}`, 'GET', getSessionCookie().accessToken, 'application/json', Cookies.get('csrfToken')!);
        console.log(res)
        return res;
    }

    static async  resetPassword(email : string) {
        const data = {
            email: email
        }        
        const response = await fetchAPI('api-user/reset-password', "POST", '' , 'application/json', Cookies.get('csrfToken')!, data);
        return response;
    }

    static async recoverPassword(code :string, newPassword: string) {
        const data = {
            code: code,
            newPassword: newPassword
        };

        const response = await fetchAPI('api-user/recover-mdp', "PUT", "", 'application/json',  Cookies.get('csrfToken')!, data);
        return response;
    }

    static async get(id: string, session: Session) {
        const json = await fetchAPI(`api-user/profile-image/${id}`, 'GET', getSessionCookie().accessToken,'application/json', Cookies.get('csrfToken')!);
        return json;
    }

    
}

export default UserAPI;