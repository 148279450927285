interface ScenarioNavigationProps {
    currentPage: number;
    totalScenarios: number;
    scenariosPerPage: number;
    onPageChange: (page: number) => void;
}

const ScenarioNavigation: React.FC<ScenarioNavigationProps> = ({ currentPage, totalScenarios, scenariosPerPage, onPageChange }) => {

    const totalPages = Math.ceil(totalScenarios / scenariosPerPage);

    const navigateToPage = (page: number) => {
        if (page >= 0 && page < totalPages) {
            onPageChange(page);
        }
    };

    return (
        <div className="scn-nav-container">
            {[...Array(totalPages)].map((_, index) => (
                <button
                    key={index}
                    className={`scn-nav-btn ${currentPage === index ? 'active' : ''}`}
                    onClick={() => navigateToPage(index)}
                >
                    <div className="circle" />
                </button>
            ))}
        </div>
    );
}

export default ScenarioNavigation;