export const profileInputs = [
	{
		id: 1,
		type: "email",
		gridSize: 4,
		value: '',
		labelValue: "Email",
		labelInline: false,
	},
	{
		id: 2,
		type: "text",
		gridSize: 4,
		value: '',
		labelValue: "Numéro de téléphone",
		labelInline: false,
	},
    {
		id: 3,
		type: "text",
		gridSize: 4,
		value: '',
		labelValue: "Prénom",
		labelInline: false,
	},
    {
		id: 4,
		type: "text",
		gridSize: 4,
		value: '',
		labelValue: "Nom",
		labelInline: false,
	}
];