import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSessionCookie, getSessionCookie, removeSession } from '../helpers/Cookies';
import { SessionState, Session, UserData } from '../interfaces/API';

// Ensure the initial state is strongly typed and includes all properties of SessionState
const initialState: SessionState = {
    value: getSessionCookie(),
    isConnected: false,
    userData: null,
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSession: (state, action: PayloadAction<Session | null>) => {
            state.value = action.payload;
            setSessionCookie(state.value);
            if (!state.value) {
                removeSession();
            }
        },
        isConnected: (state) => {
            state.isConnected = !!(
                state.value &&
                state.value.accessToken &&
                state.value._id
            );
        },
        setUserData: (state, action: PayloadAction<UserData | null>) => {
            state.userData = action.payload;
        },
        updateUserData: (state, action: PayloadAction<Partial<UserData>>) => {
            state.userData = {
                ...state.userData,
                ...action.payload,
            } as UserData; // Ensure the type is correct by asserting it as UserData
        },
    },
});

export const {
    setSession,
    setUserData,
    updateUserData,
    isConnected,
} = sessionSlice.actions;

export default sessionSlice.reducer;