import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { RootState } from '../../redux/ReduxStore';
import { setPage } from '../../redux/Page';
import { NavElementProp } from "../../interfaces/Navbar";

import NavbarIcons from "./IconsExport";
import SFLogo from "../../assets/images/logos/SFLogo.png";

import translate from "../translate/Translate"
import { useLanguage } from '../languageProvider/LanguageContext';

const Navbar = () => {
const { lang, changeLanguage } = useLanguage();

    const navigate = useNavigate();
    const currentPage = useSelector((state: RootState) => state.page.currentPage);
    const dispatch = useDispatch();

    const NavElement = ({ value, name, icon, iconHlg } : NavElementProp) => {

        function navbarNavigation(value: string) {
            dispatch(setPage(value));
            navigate(`/${value}`);
        }

        return (
            <button className={"nav-button" + ((value === currentPage) ? " selected" : "") } onClick={() => navbarNavigation(value)}>
                <div className="nav-element">
                    <img alt="File icon" src={ (value === currentPage) ? iconHlg : icon } className="icon" />
                    <p className="name">{ name }</p>
                </div>
            </button>
        )
    }
    
    return (
        <div className="component navbar-container">
            <img alt="Simply file logo" className="logo" src={ SFLogo } />
            <h5 className="category-title first">{'Pages'}</h5>
            <NavElement
                value={ "dashboard" }
                name={"Dashboard"}
                icon={ NavbarIcons.dashboardIcon }
                iconHlg={ NavbarIcons.dashboardHlgIcon }
            />
            <NavElement
                value={ "scenarios" }
                name={translate ("Scenarios", lang) }
                icon={ NavbarIcons.scenarioIcon }
                iconHlg={ NavbarIcons.scenarioHlgIcon }
            />
            <NavElement
                value={ "upload"}
                name={ translate("Add",lang) }
                icon={ NavbarIcons.addDocumentIcon }
                iconHlg={ NavbarIcons.addDocumentHlgIcon }
            />
            <div className="hor-divider-60"></div>
            <h5 className="category-title second">{translate('Settings',lang)}</h5>
            <NavElement
                value={ "profile" }
                name={ "Profile" }
                icon={ NavbarIcons.profileIcon }
                iconHlg={ NavbarIcons.profileHlgIcon }
            />
            <NavElement
                value={ "settings" }
                name={ translate("Settings",lang) }
                icon={ NavbarIcons.settingsIcon }
                iconHlg={ NavbarIcons.settingsHlgIcon }
            />
            <NavElement
                value={ "Logout" }
                name={ "Logout"}

                icon={ NavbarIcons.notificationIcon }
                iconHlg={ NavbarIcons.notificationHlgIcon }
            />
        </div>
    );
}

export default Navbar;