const swUrl = `https://alfred-app.fr/firebase-messaging-sw.js`;


export function register() {
    if ('serviceWorker' in navigator) {
      const swUrl = '/firebase-messaging-sw.js'; // Assure-toi que ce chemin est correct
  
      fetch(swUrl)
        .then(response => {
          if (response.ok) {
            return navigator.serviceWorker.register(swUrl);
          } else {
            throw new Error('Service worker script not found');
          }
        })
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    } else {
      console.warn('Service Workers are not supported in this browser.');
    }
  }
  
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }
}