import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../components/languageProvider/LanguageContext';
import translate from "../../components/translate/Translate";
import franceIcon from "../../assets/icons/france.svg";
import englishIcon from "../../assets/icons/united.svg";
// import './Feedback.scss'; // Import the SCSS file for styling

interface MockData {
    id: number;
    question: string;
    placeholder: string;
}

const Feedback = () => {
    const navigate = useNavigate();
    const { lang, changeLanguage } = useLanguage();
    const [responses, setResponses] = useState<Record<number, string>>({});
    const [currentIndex, setCurrentIndex] = useState(0);

    // Mock data
    const mockData: MockData[] = [
        { id: 1, question: "Comment gérez-vous vos documents administratifs ?", placeholder: "Votre réponse ici" },
        { id: 2, question: "Quel type de documents utilisez-vous le plus ?", placeholder: "Votre réponse ici" },
        { id: 3, question: "Comment évalueriez-vous la procédure d'inscription et de configuration initiale ?", placeholder: "Votre réponse ici" },
        { id: 4, question: "Premier aperçu de l'application (Thèmes, couleurs, etc..). Note sur 5", placeholder: "Votre réponse ici" },
        { id: 5, question: "L'interface utilisateur est-elle intuitive ?", placeholder: "Votre réponse ici" },
        { id: 6, question: "Avez-vous rencontré des bugs ou des problèmes techniques ?", placeholder: "Votre réponse ici" },
        { id: 7, question: "Si vous avez rencontré des bugs ou des problèmes technique, veuillez les notifier", placeholder: "Votre réponse ici" },
        { id: 8, question: "Quelle est la probabilité que vous recommandiez cette application à un ami ou collègue ?", placeholder: "Votre réponse ici" },
        { id: 9, question: "Avez-vous des suggestions pour améliorer l'application ?", placeholder: "Votre réponse ici" },
    ];

    const handleDownload = async () => {
        alert("click")
    };

    const handleUnderstand = () => {
        navigate('/authentification');
    };

    const handleFrenchLanguage = () => {
        changeLanguage("fr");
    };

    const handleEnglishLanguage = () => {
        changeLanguage("en");
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        setResponses(prevResponses => ({
            ...prevResponses,
            [id]: event.target.value
        }));
    };

    const goToNext = () => {
        if (currentIndex < mockData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const goToPrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const { question, placeholder, id } = mockData[currentIndex];

    return (
        <div className="page auth-container">
            <div className="component disclaimer-component disclaimer-component">
                <div className="disclaimer-background-item">
                    <div className="disclaimer-container-title">
                        <div className="container-left"></div>
                        <h3 className="title">FeedBack</h3>
                        <div className="container-right">
                            {/* <button className='btn-languages' onClick={handleFrenchLanguage}>
                                <img src={franceIcon} alt="French Icon" />
                            </button>
                            <button className='btn-languages' onClick={handleEnglishLanguage}>
                                <img src={englishIcon} alt="English Icon" />
                            </button> */}
                        </div>
                    </div>

                    <div className='disclaimer-content'>
                        <h1>{translate("Few questions on our app", lang)}</h1>
                        <div className="feedbacks-content">
                            <div className='question-text'>
                                <button className="prev-button" onClick={goToPrevious}>&lt;</button>
                                {translate(question, lang)}
                                <button className="next-button" onClick={goToNext}>&gt;</button>
                            </div>

                            <div className="feedback-item">
                                <input
                                    type="text"
                                    placeholder={placeholder}
                                    value={responses[id] || ''}
                                    onChange={(e) => handleInputChange(e, id)}
                                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                />
                            </div>
                        </div>

                        <button className='disclaimer-download-btn' onClick={handleDownload}>
                            <p>{translate("Confirm", lang)}</p>
                        </button>

                        {/* Question Counter */}
                        <div className="question-counter">
                            {`Question ${currentIndex + 1} of ${mockData.length}`}
                        </div>
                    </div>

                    <div className="disclaimer-content"></div>
                </div>
                <div className="disclaimer-background-item-thickness"></div>
            </div>
        </div>
    );
}

export default Feedback;
