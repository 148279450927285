export const signInInputs = [
	{
		id: 1,
		type: "text",
		gridSize: 12,
		value: '',
		labelValue: "Adresse Mail",
		labelInline: false,
	},
	{
		id: 2,
		type: "password",
		gridSize: 12,
		value: '',
		labelValue: "Mot de passe",
		labelInline: false,
	}
];