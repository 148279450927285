import { TextInputProp } from "../../interfaces/Form";
import translate from "../translate/Translate";

const TextInput = ({ id, type, gridSize, value, onChange, name, labelValue, labelInline }: TextInputProp) => {

    return (
        <div
            id={ type + "-type-" + id }
            className={ "input-div size-" + gridSize + (labelInline ? " label-inline" : "") }>
                { labelValue !== "" && <p>{ labelValue }</p> }
                <input
                    onChange={(e) => onChange ? onChange(e) : null}
                    value={ value?.toString() }
                    className="flex-input"
                    type={ type }
                    name={ `${type}-${(name ? name + '-' : "")}${id}` } />
        </div>
    );
}
 
export default TextInput;