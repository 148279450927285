import { fetchAPI } from "./FetchAPI";
import { StockageData } from "../../interfaces/Stockage";
import { getSessionCookie } from "../Cookies";
//{ "token": "your_jwt_token", "text": "Success" }
import { FileType } from "../../interfaces/Stockage";
import Cookies from 'js-cookie';


export class Stockage {
    private TotalFile: number;
    private TotalSizeUsed: number;
    private usedSpace: number;
    private freeSpace: number;
    private maxSize: number;
    private data: FileType[];

    constructor(stockageData: StockageData) {
        this.TotalFile = stockageData.TotalFile;
        this.TotalSizeUsed = stockageData.TotalSizeUsed;
        this.usedSpace = stockageData.usedSpace;
        this.freeSpace = stockageData.freeSpace;
        this.maxSize = stockageData.maxSize;
        this.data = stockageData.data;
    }

    toJSON(): StockageData {
        return {
            TotalFile: this.TotalFile,
            TotalSizeUsed: this.TotalSizeUsed,
            usedSpace: this.usedSpace,
            freeSpace: this.freeSpace,
            maxSize: this.maxSize,
            data: this.data
        };
    }
}


class StockageAPI {
    static async getAll() {
        const json = await fetchAPI('/storage/info', 'GET', getSessionCookie().accessToken, 'application/json', Cookies.get('csrfToken')!);
        console.log("res json:",json)
        return json
    }
}

export default StockageAPI;