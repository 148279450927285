type InputObject = Record<string, string | boolean>;

export function objectFormatter(obj: InputObject, keysArray: string[]): Record<string, string | boolean> {
    const formattedObject: Record<string, string | boolean> = {};

    // Create a mapping of order ID to keys
    const orderToKeys: Record<number, string> = {};

    Object.keys(obj).forEach(key => {
        const array = key.split('-');
        if (array.length < 3) {
            orderToKeys[parseInt(array[1])] = key;
        } else {
            orderToKeys[parseInt(array[2])] = key;
        }
    });

    // Iterate through the array and map keys
    keysArray.forEach((value, index) => {
        const key = orderToKeys[index + 1]; // Adding 1 because order IDs start from 1
        if (key) {
            formattedObject[value] = obj[key];
        }
    });

    return formattedObject;
}