import React, { useEffect, useState } from "react";
import { AboutUs, Contact, FAQ, Home, RoadMap, Services, WebsiteNavbar } from "../../components/website";

const CorporateWebsite: React.FC = () => {
    const [scrollElements, setScrollElements] = useState<NodeListOf<Element> | null>(null);
    const [appearElements, setAppearElements] = useState<NodeListOf<Element> | null>(null);
    const [navItems, setNavItems] = useState<NodeListOf<Element> | null>(null);
    const [navIndex, setNavIndex] = useState<Element | null>(null);

    useEffect(() => {
        setScrollElements(document.querySelectorAll(".js-scroll"));
        setAppearElements(document.querySelectorAll(".js-appear"));
        setNavItems(document.querySelectorAll(".nav-item"));
        setNavIndex(document.querySelector("#nav-index"));
    }, []);

    useEffect(() => {
        handleAppearAnimation();
    }, [appearElements]);

    useEffect(() => {
        const handleScroll = () => {
            throttle(() => {
                handleScrollAnimation();
                handleNavbarAnimation();
            }, 250);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollElements, navItems]);

    const elementInView = (el: Element, dividend = 1) => {
        const elementTop = el.getBoundingClientRect().top;
        return elementTop <= (window.innerHeight || document.documentElement.clientHeight) / dividend;
    };

    const elementOutofView = (el: Element) => {
        const elementTop = el.getBoundingClientRect().top;
        return elementTop > (window.innerHeight || document.documentElement.clientHeight);
    };

    const displayAnimationElementWithDelay = (element: Element, index: number, anim: string, delay: number) => {
        setTimeout(() => {
            element.classList.add(anim);
        }, (index + 1) * delay);
    };

    const displayAnimationElement = (element: Element, anim = "scrolled") => {
        element.classList.add(anim);
    };

    const hideScrollElement = (element: Element, anim = "scrolled") => {
        element.classList.remove(anim);
    };

    const handleScrollAnimation = () => {
        scrollElements?.forEach((el) => {
            if (elementInView(el, 1)) {
                displayAnimationElement(el);
            } else if (elementOutofView(el)) {
                hideScrollElement(el);
            }
        });
    };

    const handleNavbarAnimation = () => {
        const el = navIndex?.getBoundingClientRect().top || 0;
        navItems?.forEach((el) => {
            if (el.classList.contains("scrolled") || el.classList.contains("appeared")) {
                el.classList.remove('scrolled');
                el.classList.remove('appeared');
            }
        });
        if (el - 100 < 0) {
            navItems?.forEach((el) => {
                el.classList.add('scrolled');
                el.classList.add('appeared');
            });
        }
    };

    const handleAppearAnimation = () => {
        appearElements?.forEach((el, ind) => {
            displayAnimationElementWithDelay(el, ind, "appeared", 100);
        });
    };

    const throttle = (callback: () => void, time: number) => {
        let throttleTimer: NodeJS.Timeout | null = null;
        if (throttleTimer) return;

        throttleTimer = setTimeout(() => {
            callback();
            throttleTimer = null;
        }, time);
    };

    return (
        <div className="page-web" id="home">
            <WebsiteNavbar />
            <Home />
            <Services />
            <AboutUs />
            <RoadMap />
            <FAQ />
            <Contact />
        </div>
    );
};

export default CorporateWebsite;