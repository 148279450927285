import DateInput from './DateInput';
import RadioInput from './RadioInput';
import SwitchInput from './SwitchInput';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import FileInput from './FileInput';

import { InputProp, RadioInputProp, TextInputProp, SelectInputProp } from '../../interfaces/Form';

import translate from "../translate/Translate"

interface InputComponentProp {
    inputs: InputProp[];
    justifyContent: string;
    alignItems: string;
    onInputChange: (name: string, value: string | boolean | React.ChangeEvent<HTMLInputElement>) => void;
    onFileChange?: (event: React.ChangeEvent<HTMLInputElement> | FileList) => void;
}

const Input = ({ inputs = [], justifyContent = "flex-start", alignItems = "flex-start", onInputChange, onFileChange }: InputComponentProp) => {

    return (
        <div className={"input-container justify-" + justifyContent + " align-" + alignItems}>
            {inputs?.map((input, index) => {
                switch (input.type) {
                    case 'text':
                    case 'email':
                    case 'password':
                    case 'phone':
                        return (
                            <TextInput
                                id={input?.id}
                                key={index}
                                type={input?.type}
                                gridSize={input?.gridSize}
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    onInputChange(name, value);
                                }}
                                name={(input as TextInputProp).name}
                                labelValue={(input as TextInputProp).labelValue}
                                labelInline={(input as TextInputProp).labelInline}
                            />
                        );

                    case 'radio':
                        return (
                            <RadioInput
                                id={input?.id}
                                key={index}
                                type={input?.type}
                                gridSize={input?.gridSize}
                                options={(input as RadioInputProp)?.options}
                                name={(input as RadioInputProp)?.name}
                                onChange={(value) => {
                                    onInputChange(input?.id.toString(), value);
                                }}
                            />
                        );

                    case 'switch':
                        return (
                            <SwitchInput
                                id={input?.id}
                                key={index}
                                type={'checkbox'}
                                gridSize={input?.gridSize}
                                value={input?.value || false}
                                onChange={(id, value) => {
                                    onInputChange(input?.id.toString(), value);
                                }}
                            />
                        );

                    case 'select':
                        return (
                            <SelectInput
                                id={input?.id}
                                key={index}
                                type={'checkbox'}
                                gridSize={input?.gridSize}
                                options={(input as SelectInputProp)?.options}
                                value={input?.value}
                                onChange={(value) => {
                                    onInputChange(input?.id.toString(), value);
                                }}
                            />
                        );

                    case 'date':
                        return (
                            <DateInput />
                        );

                    case 'file':
                        return (
                            <FileInput
                                id={input?.id}
                                key={index}
                                type={input.type}
                                gridSize={input?.gridSize}
                                value={input?.value}
                                multiple={input?.multiple}
                                onChange={(e) => {
                                    if (onFileChange) {
                                        if (input?.multiple && e.target.files) {
                                            onFileChange(e.target.files);
                                        } else {
                                            onFileChange(e);
                                        }
                                    }
                                }}
                            />
                        );

                    default:
                        return null;
                }
            })}
        </div>
    );
}

export default Input;