import { configureStore, combineReducers } from '@reduxjs/toolkit';
import scenarioReducer from './Scenario';
import sessionReducer from './Session';
import pageReducer from './Page'
import toastReducer from './Toast'

const rootReducer = combineReducers({
	scenario: scenarioReducer,
	session: sessionReducer,
	page: pageReducer,
	toast: toastReducer,
});

const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;