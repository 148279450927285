import { useEffect, useState } from "react";
import { SignIn, SignUp } from "../../components/auth";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/modal/Modal";
import { resetPasswordInputs } from "../../components/form/objects/resetPasswordInputs";
import { Form } from "../../components/form";
import translate from "../../components/translate/Translate"
import { resetEmailInputs } from "../../components/form/objects/resetEmailInputs";
import { resetConfirmationCodeInputs } from "../../components/form/objects/resetConfirmationCodeInputs";
import { objectFormatter } from "../../helpers/utils/objectFormatter";
import { codePinInputs } from "../../components/form/objects/codePinInputs";
import UserAPI from "../../helpers/api/UserAPI";
// import {askNotificion} from '../../helpers/notification'


export interface AuthComponentProps {
    isShown: boolean;
    setIsSignUp: (value: boolean) => void;
    setShowModal?: (value: boolean) => void;

}

const AuthentificationPage = () => {

    const [isSignUp, setIsSignUp] = useState(true);
    const [isSignIn, setIsSignIn] = useState(true);
    
    const [showModal, setShowModal] = useState(false);

    const [showSndShowModal, setshowSndShowModal] = useState(false)
    const [showThdShowModal, setshowThdShowModal] = useState(false)
    const [showPinModal, setshowPinModal] = useState(false)

    const navigate = useNavigate();

    const toggleModalPin = () => {
        setshowPinModal(!showPinModal);
    } 

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    
    const toggleSndModal = () => {
        setShowModal(!showModal);
        setshowSndShowModal(true);
    };

    // const hideModal = () => {
    //     setShowModal(false);
    //     setshowSndShowModal(false);
    //     setshowThdShowModal(false);
    // }
    // const toggleThdModal = () => {
    //     setshowSndShowModal(!showSndShowModal);
    //     setshowThdShowModal(true);
    // }

    const parseFormValuesEmail = (formValues: Record<string, string | boolean>): Record<string, string | boolean> => {
        const array = ["email"];
        const formatteObj = objectFormatter(formValues, array);
        console.log("formared", formatteObj)
        const containsAtLeastOneAt = Object.values(formatteObj).some(value => typeof value === 'string' && value.includes('@'));

        if (!containsAtLeastOneAt) {
            // If no "@" symbol found, throw an error or handle it accordingly
            alert('None of the formatted values contain "@" symbol.');
        }
        return formatteObj;
    };

    const parseFromValuesPin = (formValues: Record<string, string | boolean>): Record<string, string | boolean> => {
        const array = ["pin"];
        const formatteObj = objectFormatter(formValues, array);
        // console.log("formared", formatteObj)
        if (formatteObj.pin && typeof formatteObj.pin === 'string') {
            // Check if the "pin" value consists only of numbers
            if (!/^\d+$/.test(formatteObj.pin)) {
                // Trigger an alert if "pin" contains non-numeric characters
                alert("Pin should contain only numbers.");
                return {};
            }
            if (formatteObj.pin.length > 6) {
                alert("Pin should be maximum 6 characters long.");
                return {};
            }
        }
        console.log("formared", formatteObj)

        return (formatteObj)
    };

    useEffect(() => {
    }, []);

    const handleSubmit = async (data: {pin : string}) => {
		try {
			    const response = await UserAPI.confirmMail(data.pin);
                console.log("response:", response.text);
                if (response.message == 'Code valide') {
                    // askNotificion()
                    navigate('/dashboard')
                }
                toggleSndModal();
		} catch (error) {
			console.error('Error trying to login:', error);
		}
	};

    const handleSndSubmit = async (data: {email: string}) => {

        try {
            const response = await UserAPI.resetPassword(data.email);
            console.log("response:", response.text);
            navigate('/changePassword/:name/:id')
        // }
    } catch (error) {
        console.error('Error trying to login:', error);
    }
        };

    return (
        <div className="page auth-container">
            <SignIn isShown={ isSignUp } setIsSignUp={ setIsSignUp } setShowModal={ toggleModal } ></SignIn>
            <SignUp isShown={ isSignUp } setIsSignUp={ setIsSignUp } setShowModal={ toggleModalPin }></SignUp>

		    <Modal
                title={"Changement de mot de passe"}
                size="sm"
                show={ showModal }
                onHide={ toggleModal }
                passedForm={
                    <Form
                        inputs={ resetEmailInputs}
                        style={{ justify: "flex-center", align: "" }}
                        button={{
                            active: true,
                            name: "CHANGER",
                            style: {
                                justify: "flex-center",
                                align: "flex-center",
                                gridSize: 12,
                                color: 'dark'
                            }}}
                        navigate={{ active: false }}
                        handleFunction={handleSndSubmit}
                        parseFormValues={parseFormValuesEmail}
                    />
            }/>
		    <Modal
                title={"Code pin envoyé par mail"}
                size="sm"
                show={ showPinModal }
                onHide={ toggleModalPin }
                passedForm={ 
                    <Form
                        inputs={ codePinInputs}
                        style={{ justify: "flex-center", align: "" }}
                        button={{
                            active: true,
                            name: "Confirmer",
                            style: {
                                justify: "flex-center",
                                align: "flex-center",
                                gridSize: 12,
                                color: 'dark'
                            }}}
                        navigate={{ active: false }}
                        handleFunction={handleSubmit}
                        parseFormValues={parseFromValuesPin}
                    />
            }/>


        </div>
    );
}
 
export default AuthentificationPage;