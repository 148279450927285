import React, {Suspense} from 'react';
import { Navbar } from '../../components/navbar';
import { Form } from '../../components/form';
// import { PieChart } from '../../components/chart'; 
import { useNavigate } from "react-router-dom";
import translate from "../../components/translate/Translate"
import StockageAPI from "../../helpers/api/StockageAPI";
import { StockageData } from "../../interfaces/Stockage";
import { useEffect, useState } from "react";
import { useLanguage } from '../../components/languageProvider/LanguageContext';

const PieChart = React.lazy(() => import('../../components/chart/PieChart'));


const DetailPage: React.FC = () => {
  const { lang, changeLanguage } = useLanguage();

  // const mockedFileData = [
  //   { name: 'document.txt', type: 'Text Document', size: '2 MB', color: getRandomColor(), numberofFiles: 10 },
  //   { name: 'document.txt', type: 'Text Document', size: '4 MB', color: getRandomColor(),numberofFiles: 10 },
  //   { name: 'image.jpg', type: 'Image', size: '1.5 MB', color: getRandomColor(),numberofFiles: 10 },
  //   { name: 'spreadsheet.xlsx', type: 'Spreadsheet', size: '3 MB', color: getRandomColor(),numberofFiles: 10 },
  // ];

  // const mockStockageData: StockageData = {
  //   usedSpace: 800,
  //   maxSize: 1000,
  //   TotalSizeUsed: 1200,
  //   TotalFile: 50,
  //   freeSpace: 200,
  //   data: [
  //     { typeName: "Documents", nbfile: 20, sizetotal: 500 },
  //     { typeName: "Images", nbfile: 15, sizetotal: 300 },
  //     { typeName: "Videos", nbfile: 10, sizetotal: 400 },
  //     { typeName: "Audio", nbfile: 5, sizetotal: 100 }
  //   ]
  // };

  const [storageData, setStorageData] = useState<StockageData | null>(null)

  useEffect(() => {
      async function fetchData() {
          try {
            const data: StockageData = await StockageAPI.getAll();
            setStorageData(data);
            console.log('data:',data)

            // setStorageData(mockStockageData)
            // console.log('mockdata:',mockStockageData)
          } catch (error) {
          }
        }
        fetchData();
    }, []);


  const navigate = useNavigate();
  const mockedSizes: number[] = storageData?.data.map(file => file.sizetotal || 0) || [];
  
  ; 
  // const mockedColors = storageData?.data.map(() => getRandomColor());
  const mockedColors: string[] = storageData?.data.map(() => getRandomColor()) || [];

console.log("size all docs:",mockedSizes)
console.log("colors: :",mockedColors)

 
  // const totalFiles = mockedFileData.length;

  return (
    <div className="page scenario-view">
      <div className="page-container">
        <div className="container-thickness" />
        <div className="container-flat">
          <Navbar />
          <div className="vert-divider" />
          <div>
          <h2 style={{color:'white', marginTop:'70px'}}>{translate("Storage details:",lang)}</h2>
          <p style={{color:'white'}}>{translate('Page allowing you to view storage details',lang)}</p>
          <button
                    style={{
                        position: 'absolute',
                        top: '20px',
                        left: '1250px',
                        zIndex: '999',
                        background: 'transparent', // Set background to transparent
                        color: 'black', // Set text color to white
                        fontSize: '20px', // Adjust font size if needed
                        cursor: 'pointer',
                        
                    }}
                    onClick={() => navigate('/dashboard')}
                >
                    <div style={{color:'white'}}>x</div>
                </button>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', color: 'white' }}>
            {/* Your Table container */}
            <div style={{margin:'100px'}}>
              <h2>{translate('Documents :',lang)}</h2>
              <Suspense fallback={<div>Piechart loading...</div>}>
                <PieChart data={mockedSizes} colors={mockedColors} />
              </Suspense>

            </div>

            {/* Legend */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px', margin: '100px 0px', height: '300px', width: '250px', overflow: 'auto', border: '1px solid #ccc', borderRadius: '8px' }}>

  <div style={{textAlign: 'left'}}>
  <h2>Legend : </h2>
  {storageData?.data.map((file, index) => (
    <div key={index} style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center', marginBottom: '8px' }}>
      <div
        style={{
          width: '20px',
          height: '20px',
          backgroundColor: getRandomColor(),
          marginRight: '8px',
          borderRadius: '50%',
        }}
      />
      <div>
        <div>{file.typeName}</div>
        <div style={{ fontSize: '12px', color: 'gray' }}>
          {file.typeName} {translate("size",lang)} - {file.sizetotal.toFixed(1)} MO
        </div>
        <div style={{ fontSize: '12px', color: 'gray' }}>
          {translate("number of files",lang)} - {file.nbfile}
        </div>
      </div>
    </div>
  ))}
</div>

  {/* <p>{translate("Documents :",lang)} {mockStockageData.TotalFile}</p> */}
  {translate('Documents :', lang)} {storageData?.TotalFile}
</div>

          </div>

        </div>
      </div>
    </div>
    </div>
  );
};




function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default DetailPage;



