import dashboardIcon from "../../assets/icons/navbar/blue/dashboard.svg"
import scenarioIcon from "../../assets/icons/navbar/blue/scenario.svg"
import addDocumentIcon from "../../assets/icons/navbar/blue/add-document.svg"
import profileIcon from "../../assets/icons/navbar/blue/profile.svg"
import settingsIcon from "../../assets/icons/navbar/blue/settings.svg"
import notificationIcon from "../../assets/icons/navbar/blue/notifications.svg"

import dashboardHlgIcon from "../../assets/icons/navbar/white/dashboard.svg"
import scenarioHlgIcon from "../../assets/icons/navbar/white/scenario.svg"
import addDocumentHlgIcon from "../../assets/icons/navbar/white/add-document.svg"
import profileHlgIcon from "../../assets/icons/navbar/white/profile.svg"
import settingsHlgIcon from "../../assets/icons/navbar/white/settings.svg"
import notificationHlgIcon from "../../assets/icons/navbar/white/notifications.svg"

const NavbarIcons = {
    dashboardIcon,
    dashboardHlgIcon,
    scenarioIcon,
    scenarioHlgIcon,
    addDocumentIcon,
    addDocumentHlgIcon,
    profileIcon,
    profileHlgIcon,
    settingsIcon,
    settingsHlgIcon,
    notificationIcon,
    notificationHlgIcon
};

export default NavbarIcons;