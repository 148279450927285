import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import NoFile from "../../assets/icons/no-file.svg";
import LeftArrow from "../../assets/icons/left-arrow.svg";
import RightArrow from "../../assets/icons/arrow-right.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import ParameterIcon from "../../assets/icons/parameters-icon.svg";
import { FileData, Tags } from "../../interfaces/Files";
import FilesAPI from "../../helpers/api/FileAPI";
import { useDispatch } from "react-redux";
import { setMessage } from "../../redux/Toast";
import { getSessionCookie } from "../../helpers/Cookies";
   // const testObj = {
    //     date: "06/01/2024",
    //     type: "PDF Document",
    //     firstName: "Jean",
    //     lastName: "Test",
    //     adresse: "1 rue General de Gaulle",
    //     email: "example@gmail.com",
    //     number: "09838992",
    //     price: "45.99",
    // };
const PageDetail = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { id, name } = useParams();
    const [title, setTitle] = useState("Loading...");
    const [expandDetails, setExpandDetails] = useState(false);
	const [allFiles, setAllFiles] = useState<FileData[] | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const [previewImg, setPreviewImg] = useState(false);

    useEffect(() => {
        getFiles();
        handleDownloadFilePreview();
    }, [])

    const getFiles = async () => {
        try {
            const response = await FilesAPI.getAll();
            console.log('Response documents :', response);
            setAllFiles(response);
        } catch (error) {
            if (typeof error === 'object' && error) {
                const errorMessage = (error as Error).message;
                dispatch(setMessage({ message: errorMessage, title: 'Error getting the file:'}));
            } else {
                dispatch(setMessage({ message: 'An unknown error occurred', title: 'Error getting the file:'}));
            }
            navigate('/dashboard');
        }
    };

    const handleDownloadFile = async () => {
        try {
            if (id) {
                const response = await FilesAPI.downloadFileImage(id);
                const customHeaders = new Headers();
                customHeaders.append('content', 'multipart/form-data');
                customHeaders.append('Authorization', `${getSessionCookie().accessToken}`);
                fetch(response.url, {method: 'GET', headers: customHeaders})
                .then(response => {
                    if (!response.ok) {
                    throw new Error('Network response was not ok');
                    }
                    return response.blob(); // Récupère le contenu comme un Blob
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'image.jpg');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
                // console.log('res :', response);
                // dispatch(setMessage({ message: response.message, title: 'Success:'}))
                // navigate('/dashboard');
            }
        } catch (error) {

            if (typeof error === 'object' && error) {
                const errorMessage = (error as Error).message;
                dispatch(setMessage({ message: errorMessage, title: 'Error downloading the file:'}));
            } else {
                dispatch(setMessage({ message: 'An unknown error occurred', title: 'Error downloading the file:'}));
            }
        }
    }

    const handleDeleteFile = async () => {
        try {
            if (id) {
                const response = await FilesAPI.deleteFile(id);
                // console.log('res :', response);
                dispatch(setMessage({ message: response.message, title: 'Success:'}))
                navigate('/dashboard');
            }
        } catch (error) {

            if (typeof error === 'object' && error) {
                const errorMessage = (error as Error).message;
                dispatch(setMessage({ message: errorMessage, title: 'Error deleteting the file:'}));
            } else {
                dispatch(setMessage({ message: 'An unknown error occurred', title: 'Error deleteting the file:'}));
            }
        }
    };

    const handleDownloadFilePreview = async () => {
        try {
            if (id) {
                const response = await FilesAPI.downloadFilePreview(id);
                if (!response.ok) {
                    throw new Error('API response was not ok');
                }
                const blob = await response.blob();
                const imageUrl = URL.createObjectURL(blob);
                // console.log('responsesss ;',response)
                setImageSrc(imageUrl);
                setPreviewImg(true);
                // dispatch(setMessage({ message: `File ${ id } downloaded`, title: 'Success:'}));
            }
        } catch (error) {
            setPreviewImg(false);
            if (typeof error === 'object' && error) {
                const errorMessage = (error as Error).message;
                dispatch(setMessage({ message: errorMessage, title: 'Error during file preview:'}));
            } else {
                dispatch(setMessage({ message: 'An unknown error occurred', title: 'Error during file download:'}));
            }
        }
    };

    function getFileName(fileName: string | undefined): string {
        if (name === undefined) {
            return 'Unknown File'; // or any other default value
        }

        const lastDotIndex = name.lastIndexOf('.');
        return lastDotIndex !== -1 ? name.substring(0, lastDotIndex) : name;

    }

    // useEffect(() => {
    //     if (allFiles)
    //         console.log(allFiles.filter(item => item._id === id))
    // }, [allFiles])

    interface TagsListProps {
        object: Tags[];
    }
      
    const TagsList: React.FC<TagsListProps> = ({ object }) => {

        function formatString(str: string): string {
            const words = str.split(/(?=[A-Z])/);
            const formattedString = words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())).join(' ');
            // const formattedString = words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())).join(' ');
          
            return formattedString;
        }

        return (
            <div className="tags-columns">
                <div className="tag-keys tag-col">
                    { object && object.map((item, index) => (
                        <div key={ index } className="tag-element">
                            <p>
                                { formatString(item?.name) }
                            </p>
                        </div>
                    ))}
                </div>
                <div className="tag-values tag-col">
                    { object && object.map((item, index) => (
                        <div key={ index } className="tag-element">
                            <p>
                                { item?.value }
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className="page file-detail-view">
            <div className={ "page-container " + (expandDetails ? "expand" : "") }>

                <div className="container-thickness" />
                <div className="container-flat">

                    <div className="content-left">
                        <div className="file-preview-div">
                            <div className="quit-view-div">
                                <button className="quit-btn" onClick={ () => navigate('/dashboard') }>
                                    <img src={ LeftArrow } alt="Quit and return to files" className="back-icon" />
                                </button>
                            </div>
                            <div className="file-preview">
                                { !previewImg && <img src={ NoFile } alt="Missing file preview" className="file-icon" /> }
                                { previewImg && <img
                                    className="file-preview"
                                    src={ imageSrc }
                                    width="100%"
                                    height="600px"
                                    title="file"
                                /> }
                            </div>
                            <div className="detail-btn-div">
                                <button className="details-btn" onClick={ () => setExpandDetails(!expandDetails) }>
                                    <img src={ RightArrow }
                                        alt="See file details"
                                        className="arrow-icon" />
                                </button>
                            </div>
                        </div>
                        <div className="title-text">
                            <p>
                                { name && getFileName(name) }
                                { !name && title }
                            </p>
                        </div>
                    </div>
                    { expandDetails && <div className="content-right">
                        <div className="tag-list-div">
					        <div className="vert-divider-80" />
                            <div className="tag-list">
                                { allFiles && allFiles
                                    .filter(item => item._id === id)
                                    .map(filteredItem => (
                                        <TagsList key={filteredItem._id} object={(filteredItem as FileData).tags} />
                                ))}
                            </div>
                        </div>
                        <div className={ "file-parameters-div " + (expandDetails ? "appeared slide-right" : "") }>
                            <button className="file-btn parameters">
                                <img src={ ParameterIcon } alt="Parameters button icon" className="param-btn" />
                            </button>
                            <button className="file-btn download">
                                <img src={ DownloadIcon } onClick={ () => handleDownloadFile() } alt="Download button icon" className="download-btn" />
                            </button>
                            <button className="file-btn delete">
                                <img src={ DeleteIcon } onClick={ () => handleDeleteFile() } alt="Delete button icon" className="delete-btn" />
                            </button>
                        </div>
                    </div> }
                </div>
            </div>
        </div>
    );
}
 
export default PageDetail;