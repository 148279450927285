import CrossIcon from '../../assets/icons/black-cross-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/ReduxStore';
import { setMessage } from '../../redux/Toast';
import { useEffect, useState } from 'react';

const Toaster = () => {
    const dispatch = useDispatch();
    const message = useSelector((state: RootState) => state.toast.toastMessage);
    const title = useSelector((state: RootState) => state.toast.toastTitle);
    const [timerActive, setTimerActive] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (message) {
            setTimerActive(true);
            timer = setTimeout(() => {
                dispatch(setMessage({ message: '', title: 'New message'}));
            }, 10000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [dispatch, message]);

    useEffect(() => {
        setTimerActive(!!message);
    }, [message]);

    return (
        <div className={ "toast-container slide-right " + (message ? "appeared" : "") }>
            <div className="toast-header">
                <p className='title'>
                    { title }
                </p>
                <button
                    onClick={ () => dispatch(setMessage({ message: '', title: 'New message'})) }
                    className="close-btn">
                    <img src={ CrossIcon } alt="Closing icon" className="btn-icon" />
                </button>
            </div>
            <div className="hor-divider" />
            <div className="toast-body">
                <p>{ message }</p>
            </div>
            <div className="hor-divider" />
            <div className="toast-footer">
                <button onClick={ () => dispatch(setMessage({ message: '', title: 'New message'})) } className="close-btn">
                    <p>Fermer</p>
                </button>
            </div>
            <div className={ `timer ${timerActive ? 'start' : ''} ` }>
                <div className="timer-bar-container">
                    <div className="timer-full"/>
                    <div className="timer-hidden"/>
                </div>
            </div>
        </div>
    );
};

export default Toaster;
