import fileIcon from "../../assets/icons/file-svgrepo-com.svg"
import { DashboardComponent } from "../../interfaces/Dashboard";
import translate from "../translate/Translate"
import { useLanguage } from "../languageProvider/LanguageContext";

const QuickAccess =({ display } : DashboardComponent) => {
    const { lang, changeLanguage } = useLanguage();

    return (
        <div className={ "access-div " + (display ? "shown" : "hidden") + "-div" }>
            <div className="access-thickness"/>
            <div className="access-flat">
                <div className="title">
                    <p>
                    {translate("Quick access",lang)}
                    </p>
                </div>
                <div className="file-access">
                    <img alt="File icon" src={ fileIcon } className="icon" />
                    <p>Facture 1</p>
                    <p>01/01/2024</p>
                </div>
                <div className="hor-divider"/>
                <div className="file-access">
                    <img alt="File icon" src={ fileIcon } className="icon" />
                    <p>Facture 2</p>
                    <p>01/01/2024</p>
                </div>
                <div className="hor-divider"/>
                <div className="file-access">
                    <img alt="File icon" src={ fileIcon } className="icon" />
                    <p>Facture 3</p>
                    <p>01/01/2024</p>
                </div>
            </div>
        </div>
    )
}

export default QuickAccess;