import React, { useEffect, useState } from "react";
import { SearchBarProp } from "../../interfaces/Searchbar";
import { io, Socket } from 'socket.io-client';
import LeftArrow from "../../assets/icons/left-arrow.svg";
import { useDispatch } from "react-redux";
import { setMessage } from "../../redux/Toast";
import translate from "../translate/Translate"
import { useLanguage } from "../languageProvider/LanguageContext";
import { getSessionCookie } from "../../helpers/Cookies";


const SearchBar = ({ allFile, interactEvent, event, onSearchResult }: SearchBarProp) => {
  const { lang, changeLanguage } = useLanguage();
   
  const dispatch = useDispatch();
  
  const interact = (value: boolean) => {
    if (interactEvent) {
      interactEvent(value);
    }
  };

  const [socket, setSocket] = useState<Socket | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    try {
      const token = getSessionCookie().accessToken;
      const socketInstance = io('https://alfred-app.fr', {
        path: '/api-file/socket', // Spécifiez le chemin ici
        auth: { token }, // Envoi du token via les options de connexion
      });
      // const socketInstance = io('http://alfred-app.fr:8001');
      setSocket(socketInstance);

      return () => {
        socketInstance.disconnect();
      };
    } catch (error) {
      console.error('Error during registration:', error);
      if (typeof error === 'object' && error) {
          const errorMessage = (error as Error).message;
          dispatch(setMessage({ message: errorMessage, title: 'Error related to socket instance:'}));
      } else {
          dispatch(setMessage({ message: 'An unknown error occurred', title: 'Error related to socket instance:'}));
      }
    }
  }, []);

  useEffect(() => {
    try {
      const timer = setTimeout(() => {
        if (socket) {
          socket.emit('search', searchTerm);
        }
      }, 600);

      return () => clearTimeout(timer);
    } catch(error) {
      console.error('Error during registration:', error);
            if (typeof error === 'object' && error) {
                const errorMessage = (error as Error).message;
                dispatch(setMessage({ message: errorMessage, title: 'Socket Error:'}));
            } else {
                dispatch(setMessage({ message: 'An unknown error occurred', title: 'Socket Error:'}));
            }
    }
  }, [searchTerm, socket]);

  useEffect(() => {
    try {
      if (socket) {
        socket.on('searchResult', (result: string[]) => {
          if (onSearchResult) {
            onSearchResult(result);
          }
        });
      }

      return () => {
        if (socket) {
          socket.off('searchResult');
        }
      };
    } catch (error) {
      console.error('Error during registration:', error);
      if (typeof error === 'object' && error) {
          const errorMessage = (error as Error).message;
          dispatch(setMessage({ message: errorMessage, title: 'Socket Error:'}));
      } else {
          dispatch(setMessage({ message: 'An unknown error occurred', title: 'Socket Error:'}));
      }
    }
  }, [socket, onSearchResult]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  return (
    <div className={"searchbar-div " + (event ? "" : "expend")}>
      <div className={"return-btn-div " + (event ? "" : "appeared fade-in-top")}>
        <button className="return-btn" onClick={() => interact(true)}>
          <img className="btn-arrow" src={LeftArrow} alt="Return arrow" />
        </button>
      </div>
      <div className="searchbar ">
        <div className="searchbar-thickness" />
        <div className="searchbar-flat">
          <input
            type="text"
            onClick={() => interact(false)}
            value={ searchTerm }
            onChange={handleInputChange}
            placeholder= {translate("Search for a document...", lang)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;