import { useNavigate } from "react-router-dom";
import { FileComponent } from "../../interfaces/Dashboard";
import { FileData } from "../../interfaces/Files";
import  translate  from "../translate/Translate";
import { useLanguage } from "../languageProvider/LanguageContext";

const Files = ({ display, files } : FileComponent) => {
    const { lang, changeLanguage } = useLanguage();
    const navigate = useNavigate();

    const FileComponent = ({ _id, name, description, tags } : FileData) => {
        return (
            <button className="file-object" onClick={ () => navigate(`/file/${name}/${_id}`) }>
                <div className="file-content">
                    <p>{name}</p>
                </div>
            </button>
        )
    }

    return (
        <div className={"files-container " + (display ? "appeared fade-in-bottom" : "shown appeared fade-in-top") }>
            { files && files.map((file: FileData, index: number) => (
                <FileComponent
                    key={ index }
                    _id={ file?._id }
                    name={ file?.name }
                    description={ file?.description }
                    tags={  file?.tags }
                />
            ))}
            { !files &&
                <div className="loader">
                    <p>{translate("Loading...",lang)}</p>
                </div>
            }
        </div>
    );
}
 
export default Files;