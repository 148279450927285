import React, { useState, useEffect } from "react";
import translate  from "../translate/Translate"
import { useLanguage } from "../languageProvider/LanguageContext";

const DragEvent: React.FC = () => {
  const { lang, changeLanguage } = useLanguage();

    const [dragging, setDragging] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    useEffect(() => {
      const dragOver = (e: DragEvent) => {
        e.preventDefault();
        setDragging(true);
      };
      const dragLeave = (e: DragEvent) => {
        e.preventDefault();
        setDragging(false);
      };



      const drop = (e: DragEvent) => {
        e.preventDefault();
        setDragging(false);
        setIsLoading(true);  // start loading after drop
        setTimeout(() => setIsLoading(false), 2000); // simulate 2 seconds of loading
        // Here you can handle the dropped file
      };
      window.addEventListener("dragover", dragOver);
      window.addEventListener("dragleave", dragLeave);
      window.addEventListener("drop", drop);
      return () => {
        window.removeEventListener("dragover", dragOver);
        window.removeEventListener("dragleave", dragLeave);
        window.removeEventListener("drop", drop);
      };
    }, []);
  
    const loaderStyle = {
      position: "fixed" as const,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex" as const,
      justifyContent: "center" as const,
      alignItems: "center" as const,
      color: "#fff",
      fontSize: "2em",
      zIndex: 9999,
      background: "rgba(243, 255, 32, 0.5)",
    };
  
    const overlayStyle = {
      ...loaderStyle,
      background: "rgba(255, 0, 0, 0.5)",
    };
  
    return (
      <>
        {dragging && (
          <div
            style={overlayStyle}
          >
            {translate("Import a file?", lang)}
          </div>
        )}
        {/* {isLoading && (
          <div style={loaderStyle}>
            <p style={{marginRight:'30px'}}>Chargement</p>
            <span className="loader"></span>
          </div>
        )} */}
      </>
    );
  };
  
export default DragEvent;