import React, { useState } from "react";
import RoadMapImg from "../../assets/images/png/roadmap.png";
import translate from "../translate/Translate";
import { useLanguage } from "../languageProvider/LanguageContext";

const RoadMap = () => {
    const { lang, changeLanguage } = useLanguage();

    return (
        <div className="roadmap-section" id="roadmap">

            <div className="top-content">
                <p className="title-text js-scroll slide-left">
                    Road Map
                </p>
            </div>

            <div className="bottom-content">
                <div className="roadmap-content-container">
                    <p className="description description-top js-scroll slide-left">
                        {translate("1: Beginning | April 2023 - July 2023 | Start of the project, ideation and creation",lang)}
                    </p>
                    <p className="description description-top js-scroll slide-left">
                        {translate("2: Alpha and Beta | February 2024 - Today | First versions of the project, start of public testing",lang)}
                    </p>
                    <img className="roadmap js-scroll slide-right" src={ RoadMapImg } alt="SimplyFile Roadmap" />
                    <p className="description description-bottom js-scroll slide-right">
                        {translate('3: Consolidation | June 2024 - September 2024 | Consolidation of the project following feedback',lang)}
                    </p>
                    <p className="description description-bottom js-scroll slide-right">
                        {translate("4: Launch | October 2024 - December 2024 | Product launch on the market",lang)}
                    </p>
                </div>
                <div className="torn-effect-container">
                    <div className="torn-effect"/>
                    <div className="torn-effect"/>
                    <div className="torn-effect"/>
                    <div className="torn-effect"/>
                    <div className="torn-effect"/>
                    <div className="torn-effect"/>
                </div>
            </div>

        </div>
    );
}
 
export default RoadMap;