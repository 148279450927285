// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserAPI from "../../helpers/api/UserAPI";
import { objectFormatter } from "../../helpers/utils/objectFormatter";
import { AuthComponentProps } from "../../pages/auth/Authentification";
import { Form } from "../form";
import { signInInputs } from "../form/objects/signInInputs";
import { useDispatch } from "react-redux";
import { setMessage } from "../../redux/Toast";
import translate from "../translate/Translate"
import { isConnected, sessionSlice, setSession } from '../../redux/Session'
import { Session } from "../../interfaces/API";
// import { getSessionCookie } from "../../helpers/Cookies";
import { useLanguage } from "../languageProvider/LanguageContext";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/ReduxStore";
import { useEffect } from "react";
import { requestUserPermission, onMessageListener } from '../../helpers/notification';


const SignIn = ({ isShown, setIsSignUp, setShowModal }: AuthComponentProps) => {

	const navigate = useNavigate();
    const dispatch = useDispatch();
	const connected = useSelector((state: RootState) => state.session.isConnected);

	useEffect(() => {
		console.log("connected:", connected);
	}, []);

	const { lang, changeLanguage } = useLanguage();

	function toggleModal() {
		if (setShowModal) {
			setShowModal(!isShown);
		}
	}

	const handleSubmit = async (data: {email: string, password: string}) => {
		try {
			const response = await UserAPI.login(data.email, data.password);
			console.log("Token:", response);
			if (response.text === "Success") {
				const session: Session = {
                    name: response.name.first,
                    lastname: response.name.last,
                    email: response.email,
                    accessToken: response.token,
                    _id: response.id,
					phone: response.phone,
					googleOauthtoken: "",
					microsoftOauthtoken: "",
                }
                dispatch(setSession(session))
				dispatch(isConnected());
				requestUserPermission().then((token) => {
					if (token) {
					  console.log('Token obtained:', token);
					  UserAPI.pushToken(token)
					}
				});
				
				navigate('/dashboard');
			} else {
				// Handle unsuccessful login
				console.log("Unsuccessful login attempt. Response:", response.text);
				// You can show a message to the user here if needed
			}
		} catch (error) {
			console.error('Error during registration:', error);
            if (typeof error === 'object' && error) {
                const errorMessage = (error as Error).message;
                dispatch(setMessage({ message: errorMessage, title: 'Error during login:'}));
            } else {
                dispatch(setMessage({ message: 'An unknown error occurred', title: 'Error during login:'}));
            }
		}
	};
	   

    // 1 - Username
    // 2 - Password
    const parseFormValues = (formValues: Record<string, string | boolean>): Record<string, string | boolean> => {
        // const array = ["firstName", "lastName", "email", "password", "passwordVerif"];
        // const formatteObj = objectFormatter(formValues, array);

        // A virer plus tard
        const array = ["email", "password"];
        const formatteObj = objectFormatter(formValues, array);

		if (typeof formatteObj.password === 'string' ) {
            if (formatteObj.password.length < 7 ) {
                alert("Les mots de passe doivent contenir au moins 7 caractères !");
                return {};
            }
        } else {
            alert("Les mots de passe doivent être des chaînes de caractères !");
            return {};
        }
        return formatteObj;
    };

  	return (
		<div className={"component auth-component signin-component " + (isShown ? "" : "hidden")}>
			<div className="background-item">
				{/* <div className="background-element-1" />
				<div className="background-element-2" /> */}
				<div className="title">
					<h3 className="">{translate("Sign In",lang)}</h3>
				</div>
				<div className="content">

					<div className="top">
						<Form
							inputs={ signInInputs }
							style={{ justify: "flex-center", align: "flex-center" }}
							button={{
								active: true,
								name: translate("Login", lang),
								style: {
									justify: "flex-center",
									align: "flex-center",
									gridSize: 12,
									color: 'dark'
								}}}
							navigate={{ active: false }}
							handleFunction={ handleSubmit }
                        	parseFormValues={ parseFormValues }
						/>
					</div>

					<div className="bottom">
						<div className="link-to">
							<button className="auth-link" onClick={() => setIsSignUp(!isShown)}>
								<p>{translate("Not registered ? Register", lang)}</p>
								<div className="underline-effect">
									<div className="underline-div-hidden" />
									<div className="underline-div" />
								</div>
							</button>
						</div>

						<div className="link-to">
							<button className="auth-link" onClick={ () => toggleModal() }>
								<p>{translate("Forgot your password ?",lang)}</p>
								<div className="underline-effect">
									<div className="underline-div-hidden" />
									<div className="underline-div" />
								</div>
							</button>
						</div>
					</div>

				</div>
			</div>
			<div className="background-item-thickness"></div>
		</div>
	);
};

export default SignIn;