import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
    toastMessage: string | null;
    toastTitle: string;
}

const initialState: ToastState = {
    toastMessage: '',
    toastTitle: 'New message',
};

const toastSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<{ message: string | any; title: string }>) => {
            if (typeof action.payload.message === 'object')
                state.toastMessage = action.payload.message;
            else
                state.toastMessage = action.payload.message;
            state.toastTitle = action.payload.title;
        }
    },
});

export const { setMessage } = toastSlice.actions;

export default toastSlice.reducer;