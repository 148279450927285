import React, { CSSProperties, useState, useRef, Suspense } from 'react';
import useMapping from '../../helpers/utils/Mapping';
import { useNavigate } from 'react-router-dom';
// import SignaturePad from '../../components/signature/SignaturePad';
import ToasterBeta from '../../components/toaster/ToasterBeta';

const SignaturePad = React.lazy(() => import('../../components/signature/SignaturePad'));


// Mock subscription data
const subscriptions = [
    { 
      name: 'Subscription 1', 
      price: '$9.99/month', 
      description: 'Basic plan with limited features.', 
      detailedDescription: 'This plan includes basic features and is suitable for individuals or small businesses looking to get started with our service.',
      advantages: [
        'Advantage 1 for Subscription 1',
        'Advantage 2 for Subscription 1',
        'Advantage 3 for Subscription 1',
      ],
      keyFeatures: [
        'Basic feature set',
        'Limited support options',
        'Access to community forums',
        'Monthly updates and bug fixes',
      ],
    },
    { 
      name: 'Subscription 2', 
      price: '$19.99/month', 
      description: 'Standard plan with additional features.', 
      detailedDescription: 'Upgrade to our standard plan for additional features and enhanced support. Perfect for growing businesses.',
      advantages: [
        'Advantage 1 for Subscription 2',
        'Advantage 2 for Subscription 2',
        'Advantage 3 for Subscription 2',
      ],
      keyFeatures: [
        'Enhanced feature set',
        'Priority email support',
        'Access to tutorials and webinars',
        'Quarterly performance reports',
        'Customizable dashboard',
      ],
    },
    { 
      name: 'Subscription 3', 
      price: '$29.99/month', 
      description: 'Premium plan with all features included.', 
      detailedDescription: 'Unlock all features with our premium plan. Ideal for businesses seeking advanced functionality and priority support.',
      advantages: [
        'Advantage 1 for Subscription 3',
        'Advantage 2 for Subscription 3',
        'Advantage 3 for Subscription 3',
      ],
      keyFeatures: [
        'Comprehensive feature set',
        '24/7 priority support',
        'Access to API integrations',
        'Advanced analytics and reporting',
        'Dedicated account manager',
      ],
    },
    { 
      name: 'Subscription 4', 
      price: '$39.99/month', 
      description: 'Ultimate plan with exclusive features and support.', 
      detailedDescription: 'Get access to our ultimate plan for exclusive features, priority support, and dedicated account management. Perfect for enterprise-level needs.',
      advantages: [
        'Advantage 1 for Subscription 4',
        'Advantage 2 for Subscription 4',
        'Advantage 3 for Subscription 4',
      ],
      keyFeatures: [
        'Exclusive feature set',
        '24/7 premium support with SLA',
        'Access to beta features',
        'Customizable analytics dashboard',
        'Integration with CRM systems',
        'Enterprise-level security features',
      ],
    },
  ];

const SubscriptionPage: React.FC = () => {
  const [selectedSubscription, setSelectedSubscription] = useState<number>(0); // Initialize with the index of the first subscription
  const navigate = useNavigate();
  const learnMoreRef = useRef<HTMLDivElement>(null);

  const pageStyle: CSSProperties = {
    backgroundColor: '#2A334E',
    height: '100%',
    margin: 0,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
  };

  const titleStyle: CSSProperties = {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    marginTop: '2%',
  };

  const txtStyle: CSSProperties = {
    fontSize: '1 rem',
    fontWeight: '',
    marginBottom: '10px',
    marginTop: '4%',
  };
  const txtStyleBis: CSSProperties = {
    fontSize: '1 rem',
    fontWeight: '',
    marginBottom: '20px',
    marginTop: '1%',
  };

  const backButtonStyle: CSSProperties = {
    backgroundColor: '#4e5b6e',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  };

  const subscriptionContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  };

  const subscriptionStyle: CSSProperties = {
    backgroundColor: '#3D475B',
    margin: '10px',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '300px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '300px',
  };

  const learnMoreTitleStyle: CSSProperties = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    marginTop: '2%',
    textAlign: 'left',
    marginLeft: '20px',
    width: '100%',
  };

  const priceStyle: CSSProperties = {
    fontSize: '1.5rem',
    marginBottom: '20px',
    marginTop: '10px',
  };

  const learnMoreContainerStyle: CSSProperties = {
    marginTop: '10px',
    marginBottom: '40px',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#3D475B',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const blockContainerStyle: CSSProperties = {
    marginTop: '40px',
  };

  const dividerStyle: CSSProperties = {
    width: '50%',
    margin: 'auto',
    marginBottom: '10px',
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
  };

  const advantagesContainerStyle: CSSProperties = {
    backgroundColor: '#3D475B',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '300px',
    margin: '0 auto',
    width: '100%',
  };

  const buttonStyle: CSSProperties = {
    backgroundColor: '#008BA7',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
    marginRight: '5px',
  };

  const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  };

  const crossButtonStyle: CSSProperties = {
    position: 'absolute',
    top: '20px',
    left: '20px',
    fontSize: '1.5rem',
    cursor: 'pointer',
  };

  const confirmButtonStyle: CSSProperties = {
    display:'flex',
    justifyContent:'flex-end',
    fontSize: '1rem',
    padding: '10px 20px',
    backgroundColor: '#008BA7',
    color: 'white',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, box-shadow 0.3s',
  };

  const handleConfirmButtonClick = () => {
    // Implement logic for handling confirm button click here
  };

  const handleCrossButtonClick = () => {
    navigate('/dashboard');
  };

  const handleGetStartedClick = (index: number) => {
    console.log(`Get Started clicked for subscription ${index}`);
  };

  const handleLearnMoreClick = (index: number) => {
    console.log(`Learn More clicked for subscription ${index}`);
    setSelectedSubscription(index);
    if (learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const keyframes = `
    @keyframes slide-in-right {
      0% {
        transform: translateX(100%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
    @keyframes slide-in-left {
      0% {
        transform: translateX(-100%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  `;

  const handleSubscriptionSelect = (index: number) => {
    setSelectedSubscription(index);
  };

  const renderSubscription = (subscription: typeof subscriptions[number], index: number): React.ReactNode => {
    return (
      <div 
        key={index} 
        style={{ ...subscriptionStyle, ...(selectedSubscription === index ? { backgroundColor: '#4e5b6e' } : {}), ...(index % 2 === 0 ? { animation: 'slide-in-left 1s ease-out' } : { animation: 'slide-in-right 1s ease-out' }) }}
        onClick={() => handleSubscriptionSelect(index)}
      >
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '1.2rem', marginBottom: '10px' }}>{subscription.name}</div>
          <hr style={dividerStyle} />
          <div style={priceStyle}>{subscription.price}</div>
          <hr style={dividerStyle} />
          <div style={{ marginTop: '15px' }}>{subscription.description}</div>
          <div style={{ marginTop: '10px', fontSize: '0.9rem' }}>{subscription.detailedDescription}</div>
        </div>
        <div style={buttonContainerStyle}>
          <button style={buttonStyle} onClick={() => handleGetStartedClick(index)}>Get Started</button>
          <button style={buttonStyle} onClick={() => handleLearnMoreClick(index)}>Learn More</button>
        </div>
      </div>
    );
  };

  const renderAdvantages = (): React.ReactNode => {
    return (
      <div style={advantagesContainerStyle}>
        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{subscriptions[selectedSubscription].name}</div>
        <div style={{ marginTop: '10px', fontSize: '0.9rem' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Advantages:</div>
          <ul>
            {subscriptions[selectedSubscription].advantages.map((advantage, index) => (
              <li key={index}>{advantage}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const renderKeyFeatures = (): React.ReactNode => {
    return (
      <div style={{ marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}>
        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Key Features:</div>
        <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
          {subscriptions[selectedSubscription].keyFeatures.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderedSubscriptions = useMapping({
    of: subscriptions,
    render: renderSubscription,
  });

  const handleBackButtonClick = () => {
    console.log('Back button clicked!');
  };

  return (
    <div style={pageStyle}>
      <style>
        {keyframes}
      </style>
      <div style={crossButtonStyle} onClick={handleCrossButtonClick}>
        &#x2715;
      </div>

      <div style={txtStyle}>How much doest it cost ?</div>
      <div style={titleStyle}>Subscriptions for every need</div>
      <div style={txtStyleBis}>Every subscription plan for every budget !</div>


      <div style={subscriptionContainerStyle}>{renderedSubscriptions}</div>
      <div style={blockContainerStyle}>
        <div style={titleStyle}>Included in Simplyfile +</div>
        {renderAdvantages()}
      </div>
      <div style={learnMoreTitleStyle}>- Learn more :</div>
      <div style={learnMoreContainerStyle} ref={learnMoreRef}>
        {renderKeyFeatures()}
      </div>
      <div style={confirmButtonStyle} onClick={handleConfirmButtonClick}>
        Continue
      </div>
      {/* <Suspense fallback={<div>Loading Signature Pad...</div>}>
          <SignaturePad width={200} height={200} />
      </Suspense> */}

      <ToasterBeta/>

      
    </div>
  );
};

export default SubscriptionPage;
