import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Navbar } from "../../components/navbar";
import { Form } from "../../components/form";
import Modal from "../../components/modal/Modal";

import ScenariosAPI from "../../helpers/api/ScenarioAPI";
import FilesAPI from "../../helpers/api/FileAPI";
import { useLanguage } from "../../components/languageProvider/LanguageContext";
import { setMessage } from "../../redux/Toast";
import translate from "../../components/translate/Translate";


import { Files } from "../../interfaces/Scenarios";

interface CheckedItem {
    id: string;
    isInDatabase: boolean | undefined;
}

const ScenarioPage = () => {
    const { lang, changeLanguage } = useLanguage();

    const [title, setTitle] = useState("Loading...");
    const [scenarioName, setScenarioName] = useState("download");
    const [scenarioFiles, setScenarioFiles] = useState<Files[]>([]);
    const [checkedItems, setCheckedItems] = useState<CheckedItem[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);

    const { name, id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [name, id]);

    const fetchData = async () => {
        if (name && id) {
            const decodedString = decodeURIComponent(name);
            try {
                const response = await ScenariosAPI.getScenarioFiles('admin', id);
                setTitle(decodedString);
                setScenarioName(response.name);
                setScenarioFiles(response.files);
                updateCheckedItems(response.files);
            } catch (error) {
                handleError(error, 'Error getting scenarios:');
                navigate('/scenarios');
            }
        }
    };

    const updateCheckedItems = (files: Files[]) => {
        const newCheckedItems = files.filter(file => file.isNeeded).map(file => ({
            id: file.id,
            isInDatabase: file.isInDatabase || false,
        })).filter(item => item.id !== undefined) as CheckedItem[];
        setCheckedItems(prevState => [...prevState, ...newCheckedItems]);
    };

    const handleCheckboxChange = (id: string) => {
        setCheckedItems(prevState => {
            const existingIndex = prevState.findIndex(item => item.id === id);
            if (existingIndex !== -1) {
                return prevState.filter(item => item.id !== id);
            } else {
                const filteredItem = scenarioFiles.find(item => item.id === id);
                const newCheckedItem: CheckedItem = {
                    id,
                    isInDatabase: filteredItem ? filteredItem.isInDatabase : false,
                };
                return [...prevState, newCheckedItem];
            }
        });
    };

    const checkAddDocuments = () => {
        return checkedItems.some(item => !item.isInDatabase);
    };

    const downloadDocuments = async () => {
        const idsArray = checkedItems.map(obj => parseInt(obj.id));
        try {
            const name = scenarioName.split(' ').pop();
            if (name) {
                await ScenariosAPI.downloadScenario(idsArray, name);
            }
            resetState();
            navigate('/scenarios');
        } catch (error) {
            handleError(error, 'Error downloading ZIP folder:');
            navigate('/scenarios');
        }
    };

    const uploadFile = async (file: File | FileList) => {
        if (!file) {
            dispatch(setMessage({ message: "No file selected to upload", title: 'File upload error:' }));
            return;
        }
        setIsFileUploading(true);
        try {
            if (file instanceof FileList) {
                for (const individualFile of Array.from(file)) {
                    await uploadSingleFile(individualFile);
                }
            } else {
                await uploadSingleFile(file);
            }
            resetState();
            setShowModal(false);
        } catch (error) {
            handleError(error, 'Error uploading file');
            navigate('/scenarios');
        } finally {
            setIsFileUploading(false);
        }
    };

    const uploadSingleFile = async (file: File) => {
        try {
            const response = await FilesAPI.uploadFile(file);
            if (response) {
                dispatch(setMessage({ message: `File '${response.name}' successfully uploaded`, title: 'Success' }));
            }
        } catch (error) {
            throw new Error(`Failed to upload file '${file.name}': ${error}`);
        }
    };

    const handleError = (error: any, defaultMessage: string) => {
        const errorMessage = typeof error === 'object' && error ? (error as Error).message : 'An unknown error occurred';
        dispatch(setMessage({ message: errorMessage, title: defaultMessage }));
    };

    const resetState = () => {
        setScenarioFiles([]);
        setCheckedItems([]);
        fetchData();
    };

    return (
        <div className="page scenario-view">
            <div className="page-container">
                <div className="container-thickness" />
                <div className="container-flat">
                    <Navbar />
                    <div className="vert-divider" />
                    <div className="content-left">
                        <div className="top-content">
                            <div className="title-text">
                                <p>{title}</p>
                            </div>

                            {scenarioFiles.some(file => file.isNeeded) && (
                                <div className="subtitle-text mandatory">
                                    <p className="title">
                                        {translate("Mandatory documents",lang)}
                                    </p>

                                </div>
                            )}
                            <div className="document-list">
                                {scenarioFiles.filter(file => file.isNeeded).map(file => (
                                    <div className="document-input" key={file.id}>
                                        <div className={"is-available " + (file.isInDatabase)} />
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={true}
                                            disabled={true}
                                        />
                                        <p>{file.name}</p>
                                    </div>
                                ))}
                            </div>

                            {scenarioFiles.some(file => !file.isNeeded) && (
                                <div className="subtitle-text optional">
                                    <p>
                                        {translate("Optional documents",lang)}
                                    </p>

                                </div>
                            )}
                            <div className="document-list">
                                {scenarioFiles.filter(file => !file.isNeeded).map(file => (
                                    <div className="document-input" key={file.id}>
                                        <div className={"is-available " + (file.isInDatabase)} />
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={checkedItems.some(item => item.id === file.id) || false}
                                            onChange={() => file.id && handleCheckboxChange(file.id)}
                                        />
                                        <p>{file.name}</p>
                                    </div>
                                ))}
                                { scenarioFiles?.length <= 0 && 
                                    <p className="loader">{translate("Loading files...",lang)}</p>
                                }

                            </div>
                        </div>

                        <div className="bottom-content">

                            {scenarioFiles.length > 0 && (
                                <button
                                    type="submit"
                                    className={"download-btn " + (checkAddDocuments() ? 'grey' : '')}
                                    onClick={checkAddDocuments() ? () => setShowModal(true) : downloadDocuments}
                                >
                                    <p>{checkAddDocuments() ? 'Documents manquants' : 'Je télécharge mon dossier'}</p>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {checkedItems.length > 0 && (
                <Modal
                    title="Ajouter les documents manquants"
                    size="sm"
                    show={showModal}
                    modalClassName="scenarios"
                    onHide={() => setShowModal(false)}
                    loader={isFileUploading}
                    passedForm={
                        <Form
                            inputs={[{ id: 1, type: "file", gridSize: 8, multiple: true }]}
                            style={{ justify: "flex-center", align: "" }}
                            handleFileFunction={uploadFile}
                            button={{
                                active: true,
                                name: "AJOUTER",
                                style: { justify: "flex-center", align: "flex-end", gridSize: 12, color: 'dark' }
                            }}
                            navigate={{ active: false }}
                        />
                    }
                />
            )}
        </div>
    );
};

export default ScenarioPage;