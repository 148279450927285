import React, { useState, useEffect } from 'react'
import { AuthComponentProps } from '../auth/Authentification';
import translate from "../../components/translate/Translate"
// import translate from "../translate/Translate"
import franceIcon from "../../assets/icons/france.svg"
import englishIcon from "../../assets/icons/united.svg"
// import FilesAPI from "../../helpers/api/FileAPI";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../components/languageProvider/LanguageContext';


const Disclaimer = () => {
    const navigate = useNavigate();

    const { lang, changeLanguage } = useLanguage();
    const handleDownload = async () => {
        try {
            const response = await fetch('https://alfred-app.fr/api-file/file/betaDownload');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'filename.zip';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleUnderstand = () => {
        navigate('/authentification');
    };


    const handleFrenchLanguage = () => {
        console.log("fr");
        changeLanguage("fr");
    };

    const handleEnglishLanguage = () => {
        console.log("en");
        changeLanguage("en");
    };

    return (
        <div className="page auth-container">
        <div className={"component disclaimer-component disclaimer-component "}>
            <div className="disclaimer-background-item">
                <div className="disclaimer-container-title">
                    <div className="container-left"></div>
                    <h3 className="title">
                        Disclaimer
                    </h3>
                    <div className="container-right">
                        <button className='btn-languages' onClick={handleFrenchLanguage}>
                            <img src={franceIcon} alt="French Icon" />
                        </button>
                        <button className='btn-languages'>
                            <img src={englishIcon} alt="English Icon" onClick={handleEnglishLanguage}/>
                        </button>

                    </div>
                </div>

                <div className='disclaimer-content'>
                    <h1>{translate("Welcome to our Beta !", lang)}</h1>
                    <p className="welcome-text" style={{marginTop:'20px'}}>{translate("Welcome to our entrepreneurial project SimplyFile ! It is currently a Beta version, a test version. SimplyFile permits to manage your administrative files in the easiest way possible. Because it is a test version we recommend to not upload any sensible documents for now. If you don't have any documents of that category, you can just download the dummy documents by clicking on the button below ! Please report us any constructive criticism or any bugs you find on the app ! Enjoy trying it out !",lang)}</p>


                    <button className='disclaimer-download-btn' onClick={handleDownload}>
                        <p>{translate("Download", lang)}</p>
                    </button>
                    <button className="disclaimer-understand-btn " onClick={handleUnderstand}>
                        <p>{translate("I understand", lang)}</p>
                    </button>
                </div>
                <div className="disclaimer-content">

                </div>
            </div>
            <div className="disclaimer-background-item-thickness"></div>
        </div>
        </div>

    )
}

export default Disclaimer;