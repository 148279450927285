import { Provider } from 'react-redux';
import { AppRouter } from './routes';

import store from './redux/ReduxStore'

const App = () => {

	// Session logic

	return (
		<Provider store={ store }>
    		<AppRouter/>
  		</Provider>
		
	)
}

export default App;