import { fetchAPI } from "./FetchAPI";
import { getSessionCookie } from "../../helpers/Cookies";

import { FileData, Tags } from "../../interfaces/Files";
import Cookies from 'js-cookie';

export class FilesData {

    private _id: string;
    private name: string;
    private description: string;
    private tags: Tags[];

    constructor(scenario: FileData) {
        this._id = scenario._id;
        this.name = scenario.name;
        this.description = scenario.description;
        this.tags = scenario.tags;
    }

    toJSON(): FileData {
        return {
            _id: this._id,
            name: this.name,
            description: this.description,
            tags: this.tags,
        };
    }
}

class FilesAPI {
    static async getAll() {
        const documents = await fetchAPI('api-file/file', 'GET', getSessionCookie().accessToken, 'application/json', Cookies.get('csrfToken')!);
        return documents.map((scenario: FileData) => new FilesData(scenario).toJSON());
    }

    static async uploadFile(file : File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        const response =  await fetchAPI('api-file/file/upload', 'POST', getSessionCookie().accessToken, 'multipart/form-data',  Cookies.get('csrfToken')!, formData);
        return response;
    }

    static async deleteFile(id: string) {
        const response =  await fetchAPI(`api-file/file/${ id }`, 'DELETE', getSessionCookie().accessToken, 'application/json', Cookies.get('csrfToken')!);
        return response;
    }

    static async downloadFile(id: string) {
        const response = await fetchAPI(`api-file/file/download/${ id }`, 'GET', getSessionCookie().accessToken, 'multipart/form-data', Cookies.get('csrfToken')!);
        console.log("resp API:", typeof(response));
        return response;
    }
    static async downloadFilePreview(id: string) {
        console.log('in function :', getSessionCookie().accessToken)
        const customHeaders = new Headers();
        customHeaders.append('content', 'multipart/form-data');
        customHeaders.append('Authorization', `Bearer ${getSessionCookie().accessToken}`);
        const response = await fetch(`https://alfred-app.fr/api-file/file/download/get/id/${ id }`, {method: 'GET', headers: customHeaders});
        return response;
    }
    static async downloadFileImage(id: string) {
        console.log('in function :', getSessionCookie().accessToken)
        const customHeaders = new Headers();
        customHeaders.append('content', 'multipart/form-data');
        customHeaders.append('Authorization', `Bearer ${getSessionCookie().accessToken}`);
        const response = await fetch(`https://alfred-app.fr/api-file/file/download/${ id }`, {method: 'GET', headers: customHeaders});
        return response;
    }
}

export default FilesAPI;