import { DashboardComponent } from "../../interfaces/Dashboard";
import StockageAPI from "../../helpers/api/StockageAPI";
import { StockageData } from "../../interfaces/Stockage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import translate from "../translate/Translate";
import { useLanguage } from "../languageProvider/LanguageContext";

const SubscriptionInfo =({ display }: DashboardComponent) => {
	const navigate = useNavigate();
  const { lang, changeLanguage } = useLanguage();

 

      const handleClick = () => {
        // Redirect to another page when the container is clicked
        navigate('/detailPage');
      };


    const [storageData, setStorageData] = useState<StockageData | null>(null)

    useEffect(() => {
        async function fetchData() {
            try {
              const data: StockageData = await StockageAPI.getAll();
              setStorageData(data);

              
            } catch (error) {
            }
          }
          fetchData();
      }, []);

      const progress = storageData
      ? (storageData?.TotalSizeUsed / storageData?.maxSize) * 20
      : 0;

    return (
        <div className={ "subscription-div " + (display ? "shown" : "hidden") + "-div" }>
            <p className="title">{translate("Storage",lang)}</p>


<div className="progress-bar-container" onClick={handleClick}>
            <div className="progress-bar"></div>
        <div className="progress-bar progress-bar-blue" style={{ width: `${progress}%` }}></div>
</div>
            <div className="hor-divider"></div>
            <p className="goto">{translate("Manage my subscription",lang)}</p>
        </div>
    )
}

export default SubscriptionInfo;