import React from "react";
import translate from "../translate/Translate";
import { useLanguage } from "../languageProvider/LanguageContext";

import Xavier from "../../assets/images/profiles/Xavier.png";
import Bastien from "../../assets/images/profiles/Bastien.png";
import Antoine from "../../assets/images/profiles/Antoine.png";
import Laurent from "../../assets/images/profiles/Laurent.png";
import Teddy from "../../assets/images/profiles/Teddy.png";
import Tom from "../../assets/images/profiles/Tom.png";


const AboutUsSection: React.FC = () => {
    const { lang, changeLanguage } = useLanguage();
    
    return (
    
        <div className="us-section" id="about">

            <div className="top-content">
                <p className="title-text js-scroll fade-in-bottom">
                    {translate("Who are we ?", lang)}
                </p>
            </div>
            <div className="bottom-content">
                <div className="left js-scroll fade-in">
                    <div className="picture-element picture-div">
                        <p>Xavier Gimeno <small>{translate("Project Manager",lang)}</small></p>
                        <img src={ Xavier } alt="Profile team" className="picture-element" />
                    </div>
                    <div className="picture-element picture-div">
                        <p>Bastien Schektman <small>{translate("Backend manager",lang)}</small></p>
                        <img src={ Bastien } alt="Profile team" className="picture-element" />
                    </div>
                    <div className="picture-element picture-div">
                        <p>Antoine Blaise <small>{translate("Developer",lang)} Backend</small></p>
                        <img src={ Antoine } alt="Profile team" className="picture-element" />
                    </div>
                    <div className="picture-element picture-div">
                        <p>Laurent Hsia <small>{translate("Developer",lang)} Frontend</small></p>
                        <img src={ Laurent } alt="Profile team" className="picture-element" />
                    </div>
                    <div className="picture-element picture-div">
                        <p>Teddy Corrouge <small>{translate("Developer",lang)} iOS</small></p>
                        <img src={ Teddy } alt="Profile team" className="picture-element" />
                    </div>
                    <div className="picture-element picture-div">
                        <p>Tom Bignier <small>{translate("Developer",lang)} Android</small></p>
                        <img src={ Tom } alt="Profile team" className="picture-element" />
                    </div>
                </div>

                <div className="right">
                    <p className="text-pres js-scroll slide-right">
                        {translate("A team of students wanting to change administration within homes!",lang)}
                    </p>
                </div>
            </div>

        </div>
    );
};

export default AboutUsSection;