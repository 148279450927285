import { ReactNode, Children } from 'react';

interface MappingProps<T> {
    of: T[];
    render: (item: T, index: number) => ReactNode;
}

const useMapping = <T,>({ of, render }: MappingProps<T>): ReactNode[] => {
    return Children.toArray(of.map((item, index) => render(item, index)));
};


export default useMapping;
