import React, { ReactElement, useEffect } from 'react';
import WhiteCross from '../../assets/icons/white-cross-icon.svg';
import { Form } from '../form';
import { resetPasswordInputs } from '../form/objects/resetPasswordInputs';

type ModalProps = {
    title: string;
    size: string;
    show: boolean;
    modalClassName?: string;
    loader?: boolean
    onHide: () => void;
    passedForm?: ReactElement<any, any>;
};
  
const Modal: React.FC<ModalProps> = ({ title, size, show, onHide, passedForm, modalClassName, loader }) => {
    
    useEffect(() => {
        console.log(modalClassName)
    },[]);


    return (
        <div className={ "component modal-component " + (show ? "show " : "scrolled slide-left ") + modalClassName }>

            <div className={ "close-div " + (show ? "appeared slide-right" : "" )}>
                <button className={"close-btn " + (show ? "delay-bounce-2" : "" )}>
                    <img className="" alt="Modal closing button" onClick={ onHide } src={ WhiteCross }/>
                </button>
            </div>
            <div className={ "component modal-container " + (show ? "appeared fade-in-bottom" : "" )}>
                <div className="container-thickness"/>
                <div className="container-flat">
                    <div className="modal-header">
                        <div className="title">
                            <p>
                                { title }
                            </p>
                        </div>
                    </div>
                    <div className="hor-divider"></div>
                    <div className="modal-body">
                        { !loader && passedForm && React.cloneElement(passedForm) }
                        { loader && 
                            <div className="loader"/>
                        }
                    </div>
                    <div className="hor-divider"></div>
                </div>
            </div>
        </div>
    );
}
 
export default Modal;