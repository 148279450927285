// LanguageContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface LanguageContextType {
  lang: string;
  changeLanguage: (newLang: string) => void;
}

export const LanguageContext = createContext<LanguageContextType | null>(null);

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const getStoredLanguage = () => {
    return localStorage.getItem('lang') || 'en';
  };

  const [lang, setLang] = useState(getStoredLanguage());

  const changeLanguage = (newLang: string) => {
    setLang(newLang);
    localStorage.setItem('lang', newLang);
  };

  return (
    <LanguageContext.Provider value={{ lang, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
