export const signUpInputs = [
    {
        id: 1,
        type: 'text',
        gridSize: 9,
        value: '',
        name: 'firstName',
        labelValue: 'Prénom',
        labelInline: false
    },
    {
        id: 2,
        type: 'text',
        gridSize: 9,
        value: '', 
        name: 'lastName',
        labelValue: 'Nom',
        labelInline: false
    },
    {
        id: 3,
        type: 'email',
        gridSize: 9,
        value: '', 
        labelValue: 'Adresse email',
        labelInline: false
    },
    {
        id: 4,
        type: 'password',
        gridSize: 9, 
        value: '', 
        name: 'password',
        labelValue: 'Mot de passe',
        labelInline: false
    },
    {
        id: 5,
        type: 'password',
        gridSize: 9, 
        value: '', 
        name: 'passwordVerif',
        labelValue: 'Confirmation du mot de passe',
        labelInline: false
    },
    {
        id: 6,
        type: 'phone',
        gridSize: 9, 
        value: '', 
        name: 'PhoneNumber',
        labelValue: 'Numero de téléphone (optionel)',
        labelInline: false
    },
]