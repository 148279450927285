import React, { useState, useEffect } from "react";
import { Navbar } from "../../components/navbar";
import { Files, InfoCarousel, QuickAccess, SubscriptionInfo } from "../../components/dashboard";
import { SearchBar } from "../../components/searchbar";
import WelcomeText from "../../components/dashboard/WelcomeText";
import FilesAPI from "../../helpers/api/FileAPI";
import { FileData } from "../../interfaces/Files";
import SortAlphaUp from "../../assets/icons/sort-alpha-up-icon.svg";
import SortAlphaDown from "../../assets/icons/sort-alpha-down-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/Toast";
import { RootState } from "../../redux/ReduxStore";

const Dashboard = () => {
  const [triggerEvent, setTriggerEvent] = useState(true);
  const [allFiles, setAllFiles] = useState<FileData[] | undefined>(undefined);
  const [filteredFiles, setFilteredFiles] = useState<FileData[] | undefined>(undefined);
  const connected = useSelector((state: RootState) => state.session.isConnected);


  const dispatch = useDispatch();

  useEffect(() => {
    handleSubmit();
  }, []);

  useEffect(() => {
    // Whenever allFiles changes, update the filteredFiles
    setFilteredFiles(allFiles);
    console.log("connected:", connected);
    // if ()
      // setTriggerEvent()
  }, [allFiles]);

  const token = 'your-access-token';

    const handleSubmit = async () => {
        try {
            const response = await FilesAPI.getAll();
            console.log('all files : ',response);
            setAllFiles(response);
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };
	

	function filterFiles(order: string) {

		switch (order) {
			case 'up':
				if (allFiles)
					setAllFiles(allFiles.slice().sort((a, b) => a.name.localeCompare(b.name)));
				break;

			case 'down':
				if (allFiles)
					setAllFiles(allFiles.slice().sort((a, b) => b.name.localeCompare(a.name)));
				break;
		
			default:
				break;
		}
  }

  // function filterFiles(order: string) {
  //   if (filteredFiles) {
  //     const sortedFiles = filteredFiles.slice().sort((a, b) =>
  //       order === 'up' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
  //     );
  //     setFilteredFiles(sortedFiles);
  //   }
  // }

  const handleSearchResult = (searchResult: string[]) => {
    // Filter the files based on search result
    console.log('NEW FILE')
    if (searchResult && allFiles) {
      const filtered = allFiles.filter((file) => searchResult.includes(file.name));
      setFilteredFiles(filtered);
    }
  };

  return (
    <div className="page dashboard-page">
      <div className="page-container">
        <div className="container-thickness" />
        <div className="container-flat">
          <Navbar />
          <div className="vert-divider" />
          <div className={"content-left " + (triggerEvent ? "" : "trigger")}>
            <WelcomeText display={triggerEvent} />

            <InfoCarousel display={triggerEvent} />

            <SearchBar allFile={allFiles} interactEvent={setTriggerEvent} event={triggerEvent} onSearchResult={handleSearchResult}/>

            <div className={"filter-files-div " + (triggerEvent ? "hidden" : "shown")}>
              <div className="filter-files-buttons">
                <button className="filter-btn filter-alphabet-top">
                  <img src={SortAlphaUp} alt="Filter button" onClick={() => filterFiles('up')} />
                </button>
                <button className="filter-btn filter-alphabet-bottom">
                  <img src={SortAlphaDown} alt="Filter button" onClick={() => filterFiles('down')} />
                </button>
              </div>
            </div>

            <Files display={triggerEvent} files={filteredFiles} />
          </div>
          <div className={"vert-divider " + (triggerEvent ? "shown" : "hidden") + "-div"} />
          <div className={"content-right " + (triggerEvent ? "" : "trigger")}>
            <QuickAccess display={triggerEvent} />
            <SubscriptionInfo display={triggerEvent} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;