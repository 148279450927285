import { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { Dashboard } from '../pages/dashboard';
import Authentification from '../pages/auth/Authentification';
import { ScenarioPage, Scenarios } from '../pages/scenarios';
import { AddDocument } from '../pages/upload';
import { ProfilePage } from '../pages/profile';
import { SettingsPage } from '../pages/settings';
// import { ChangePassword } from '../pages/changePassword';
import { DetailPage } from '../pages/detailPage';
import { PageDetail } from '../pages/files';
import { CorporateWebsite } from '../pages/website';
// import FilePageDetail from '../pages/files/FilePageDetail';
import { Tmp } from '../pages/tmp';
import ConfirmationAccount from '../pages/account/confirmationAccount';
import ConfirmationChange from '../pages/changePassword/confirmationChange';
// import NewPassword from '../pages/changePassword/newPassword';
// import ResetPassword from '../pages/resetPassword';
import { Disclaimer } from '../pages/disclaimer';
import { Toaster } from '../components/toaster';
import { LanguageProvider } from '../components/languageProvider';
import SubscriptionPage from '../pages/subscription/SubscriptionPage';
import ToasterBeta from '../components/toaster/ToasterBeta';
import UserAPI from '../helpers/api/UserAPI';
import Cookies from 'js-cookie';
import { requestUserPermission, onMessageListener } from '../helpers/notification';
import { Feedbacks } from '../pages/feedback';



const ResetPassword = lazy(() => import('../pages/resetPassword'))
const FilePageDetail = lazy(() => import('../pages/files/FilePageDetail'))
// const ScenarioPage = lazy(() => import('../pages/scenarios').then(module => ({default: module.ScenarioPage})))
// const ScenarioPageTest = lazy(() => import('../pages/scenarios').then(module => ({default: module.ScenarioPageTest})))
// const Scenarios = lazy(() => import('../pages/scenarios').then(module => ({default: module.Scenarios})))
const NewPassword = lazy(() => import('../pages/changePassword/newPassword'))
const ChangePassword = lazy(() => import('../pages/changePassword').then(module => ({ default: module.ChangePassword })))

const AppRouter = () => {


	useEffect(() => {
		const fetchCsrfToken = async () => {
			try {
				const res = await UserAPI.getCsrf();
				console.log('test')
				if (res && res.csrfToken) {
					// Définir le token CSRF dans un cookie
					// Cookies.set('csrfToken_', res.csrfToken, { expires: 7, secure: true, sameSite: 'Strict' });
				}
			} catch (error) {
				console.error('Erreur lors de la récupération du token CSRF:', error);
			}
		};

		// Vérifiez si le token CSRF est valide avant d'appeler fetchCsrfToken
		fetchCsrfToken();
		  // Écouter les notifications entrantes
		  onMessageListener().then((payload : any) => {
			console.log('Message received: ', payload);
			// Traiter la notification ici, par exemple en affichant une alerte
			alert(`Message received: ${payload.notification?.title}`);
		  });
		
	}, []);

	return (
		<>
			<LanguageProvider>
				<Toaster />
				<Router>
					<ToasterBeta />
					<Routes>
						{/* Required auth */}
						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="dashboard" element={<Dashboard />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="upload" element={<AddDocument />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="profile" element={<ProfilePage />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="settings" element={<SettingsPage />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="scenarios" element={<Scenarios />} />
							<Route path="scenarios/:name/:id" element={<ScenarioPage />} />
							{/* <Route path="scenarios/:name/:id/test" element={<ScenarioPageTest />}/> */}
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="file/:name/:id" element={<PageDetail />} />
						</Route>
						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="changePassword" element={<ChangePassword />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="fileDetail" element={<FilePageDetail />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="resetPassword" element={<ResetPassword />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="changePassword" element={<ChangePassword />} />
							<Route path="changePassword/:name/:id" element={<ConfirmationChange />} />
							<Route path="changePassword/:name/:id/confirm" element={<NewPassword />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="detailPage" element={<DetailPage />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="tmp" element={<Tmp />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="account/:name/:id" element={<ConfirmationAccount />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="subscription" element={<SubscriptionPage />} />
						</Route>

						{/* Not required auth */}
						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="/disclaimer" element={<Disclaimer />} />
						</Route>
						<Route element={<ProtectedRoute requireAuth={false} redirectPath={'/dashboard'} />}>
							<Route path="/authentification" element={<Authentification />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} /*redirectPath={'/website'*/ />}>
							<Route path="/website" element={<CorporateWebsite />} />
						</Route>

						<Route element={<ProtectedRoute requireAuth={false} />}>
							<Route path="/feedbacks" element={<Feedbacks />} />
						</Route>

						{/* Error Handler */}
						<Route path="*" element={<Navigate to={'/disclaimer'} />} />
					</Routes>
				</Router>
			</LanguageProvider>
		</>
	);
}

export default AppRouter;